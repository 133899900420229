import { Link } from "react-router-dom";
import moment from "moment";
import { TODO } from "common/viewContent";
import parse from 'html-react-parser';
import { FOOD_MOOD_JOURNAL_FORM_ID } from "common/constants";
import * as Path from "Routes/paths";

const SingleToDo = ({ todo }) => {

  const getTodoEditLink = (todo) => {
    switch(todo.form_id) {
      case FOOD_MOOD_JOURNAL_FORM_ID:
        return Path.myLog;
      
      default:
        return `/to-do/${todo.id}/form`;
    }
  }

  return (
    <tr>
      <td className="px-3 py-2 border-0">
        <p className="theme-clr m-0 ws-normal fw-bold">
          {todo.form_name}
        </p>
      </td>
      <td className="px-3 py-2 border-0">
        <p
          className={TODO[todo.status].className}
        >
          <span className="icn me-2">
            {parse(TODO[todo.status].icon)}
          </span>
          {TODO[todo.status].status}
        </p>
      </td>
      <td className="px-3 py-2 border-0">
        <p className="theme-clr m-0">
          {moment(todo.created_at).format('MMM D, YYYY H:ss A')}
        </p>
      </td>
      <td className="px-3 py-2 border-0">
        <Link
          className="underline"
          to={getTodoEditLink(todo)}
        >
        {TODO[todo.status].actionLabel || "Finish"}
        </Link>
      </td>
    </tr>
  )
}

export default SingleToDo;