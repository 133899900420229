import moment from "moment-timezone";
import Maintenance from "pages/Maintenance";
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getBasicSettings } from "store/services/appService";
// import Maintenance from './Maintenance';

const WrappedApp = (WrappedComponent) => {
  return (props) => {
    const dispatch = useDispatch();
    const maintenanceConfig = useSelector((state) => state.App.basicSettings?.maintenance_schedule?.client);
    // console.log("maintenanceConfig", maintenanceConfig);

    useEffect(() => {
      dispatch(getBasicSettings());
    }, []);
    
    if (
      maintenanceConfig?.enabled === "yes" &&
      maintenanceConfig?.start_date &&
      maintenanceConfig?.end_date &&
      moment().isBefore(moment(maintenanceConfig?.end_date)) &&
      moment().isAfter(moment(maintenanceConfig?.start_date))
    ) {
      return <Maintenance config={maintenanceConfig}/>;
    }

    return <WrappedComponent {...props} />;
  };
};

export default WrappedApp;