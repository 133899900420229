import moment from "moment";
import React from "react";

const Maintenance = ({ }) => {
  
  return (
    <>
      <section className="maintenance-page">
        {/* <h1>Nutri-Q</h1> */}
        {/* <p>The application is currently under maintenance. Please try again later.</p> */}
      </section>
    </>
  );
};

export default Maintenance;
