import { Formik } from "formik";
import moment from "moment";
import TimePicker from "rc-time-picker";
import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logFoodOrFeeling, updateFoodOrFeelingLog } from "store/services/foodMoodJournalService";
import * as Yup from 'yup';
import LogUpdatedPopup from "./Component/Modals/LogUpdatedPopup";
import { BAD_FEELINGS, FM_BAD_FEELING_REASONS, FM_FEELINGS, TIME_FORMAT_24 } from "common/constants";
import { parseTimeString } from "helpers/common";
import * as Path from "Routes/paths";

const LogFeeling = ({ editFeelingData, user }) => {
  const [showLogUpdatedPopup, setShowLogUpdatedPopup] = useState(false);

  const navigate = useNavigate();

  const logTimeMoment = editFeelingData?.log_time ? moment(editFeelingData?.log_time, ['h:m a', 'H:m']) : moment();

  const initialValues = {
    log_type: "feeling",
    log_date: editFeelingData?.log_date ?
      moment(editFeelingData?.log_date).format("YYYY-MM-DD")
      :
      moment().format("YYYY-MM-DD"),
    log_time: moment(logTimeMoment).format(TIME_FORMAT_24),
    feelings: editFeelingData?.feelings || "",
    bad_feelings_reason: editFeelingData?.bad_feelings_reason || "",
    additional_notes: editFeelingData?.additional_notes || "",
  }

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      setSubmitting(true);
      if (editFeelingData) {
        // update log
        await updateFoodOrFeelingLog(editFeelingData.id, values);
      } else {
        // add new log
        await logFoodOrFeeling(values);
      }
      setSubmitting(false);
      // SUCCESS_MESSAGE("Feeling logged successfully");
      setShowLogUpdatedPopup(true);
      resetForm();
    } catch(err) {
      console.log("Error saving feeling log", err);
      setSubmitting(false);
    }
  }

  const getLogTime = (logTime) => {
    const parsedTime = parseTimeString(logTime);
    const parsedHours = parsedTime.get("hour");
    const parsedMinutes = parsedTime.get("minute");
    return moment().set("hour", parsedHours).set("minute", parsedMinutes);
  }

  if(user?.client_anytime_logging === "no") {
    navigate(Path.dashboard);
  }

  return (
    <section className="toDo position-relative">
      <LogUpdatedPopup 
        showPopup={showLogUpdatedPopup}
        closePopup={() => {
          setShowLogUpdatedPopup(false);
        }}
      />
      <Container fluid>
        <Row>
          <Col lg="12" className="my-2">
            <h3 className="m-0 py-1">Log a Feeling</h3>
            <h6 className="m-0 py-1">How are you feeling ?</h6>
          </Col>
        </Row>
        <Row>
          <Col lg="6" className="my-2">
            <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={validation}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
              }) => {
                // console.log("Errors", errors, values)
                return (
                  <Form onSubmit={handleSubmit} autoComplete="off" >
                    <Row>
                      <Col lg="12" className="my-2">
                        <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                          Date
                        </label>
                        <input
                          type="date"
                          className={`form-control ${errors.log_date && 'is-invalid'}`}
                          name="log_date"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.log_date}
                          autoComplete="off"
                          // disabled
                        />
                        {(errors.log_date && touched.log_date) &&
                          <p className="invalid-feedback">{errors.log_date}</p>
                        }
                      </Col>
                      <Col lg="12" className="my-2">
                        <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                          Time
                        </label>
                        <div className="">
                          <TimePicker
                            showSecond={false}
                            defaultValue={getLogTime(values.log_time)}
                            className={`form-control ${errors.log_time && 'is-invalid'}`}
                            onChange={(value) => {
                              setFieldValue("log_time", value ? moment(value).format(TIME_FORMAT_24) : "");
                            }}
                            format={TIME_FORMAT_24}
                            use12Hours={false}
                          />
                          {(errors.log_time && touched.log_time) &&
                            <p className="invalid-feedback">{errors.log_time}</p>
                          }
                        </div>
                      </Col>
                      <Col lg="12" className="my-2">
                        <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                          How are you feeling?
                        </label>
                        <Form.Select
                          className={`form-control ${errors.feelings && 'is-invalid'}`}
                          name="feelings"
                          onChange={(e) => {
                            if(!BAD_FEELINGS.includes(e.target.value.toLowerCase())) {
                              setFieldValue("bad_feelings_reason", undefined);
                            }

                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          value={values.feelings}
                          autoComplete="off"
                        >
                          <option value="">Select a Feeling</option>
                          {FM_FEELINGS && Object.keys(FM_FEELINGS).map(key => (
                            <option value={key}>{FM_FEELINGS[key]}</option>
                          ))}
                        </Form.Select>
                        {(errors.feelings && touched.feelings) &&
                          <p className="invalid-feedback">{errors.feelings}</p>
                        }
                      </Col>
                      {BAD_FEELINGS.includes(values.feelings.toLowerCase()) && (
                        <Col lg="12" className="my-2">
                          <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                            Why '{values.feelings}'?
                          </label>
                          <Form.Select
                            className={`form-control ${errors.bad_feelings_reason && 'is-invalid'}`}
                            name="bad_feelings_reason"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.bad_feelings_reason}
                            autoComplete="off"
                          >
                            <option value="">Select</option>
                            {FM_BAD_FEELING_REASONS && Object.keys(FM_BAD_FEELING_REASONS).map(key => (
                              <option value={key}>{FM_BAD_FEELING_REASONS[key]}</option>
                            ))}
                          </Form.Select>
                          {(errors.bad_feelings_reason && touched.bad_feelings_reason) &&
                            <p className="invalid-feedback">{errors.bad_feelings_reason}</p>
                          }
                        </Col>
                      )}
                      <Col lg="12" className="my-2 mb-4">
                        <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                          Additional Notes
                        </label>
                        <textarea
                          className={`form-control ${errors.additional_notes && 'is-invalid'}`}
                          name="additional_notes"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.additional_notes}
                          rows={5}
                        />
                        {(errors.additional_notes && touched.additional_notes) &&
                          <p className="invalid-feedback">{errors.additional_notes}</p>
                        }
                      </Col>
                      <Col lg="12" className="my-2">
                        <Button
                          type="submit"
                          className="d-flex btn text-white align-items-center fw-bold justify-content-center commonBtn w-100"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? 'Saving...' : 'Submit'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }}
            </Formik>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const validation = Yup.object({
  log_date: Yup.date().required("Log date is required"),
  log_time: Yup.string().required("Log time is required"),
  feelings: Yup.string().required("Feeling is required"),
  // bad_feelings_reason: Yup.string().required("Bad feeling reason is required"),
  bad_feelings_reason: Yup.string().nullable().when('feelings', {
    is: (feeling) => {
      return BAD_FEELINGS.includes(feeling.toLowerCase());
    },
    then: (schema) => schema.required("Bad feeling reason is required"),
  }),
  additional_notes: Yup.string().required("Additional notes are required"),
});

const mapStateToPros = (state) => {
  return {
    user: state.Profile.user,
    practitioner: state.Auth.practitionerDetails,
    editFeelingData: state.MyLog.editFeelingData,
  }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(LogFeeling);
