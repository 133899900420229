import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button
} from "react-bootstrap";
import { getTodoResponse } from "store/services/todoService";
import { decode } from "html-entities";
import moment from "moment";
import { connect } from "react-redux";
import { RenderAnswerField } from "components/shared/ToDoQuestionsAnswers/RenderAnswer/Index";
import AllQuestionAnswersPDF from "components/shared/PDFReportComponents/AllQuestionAnswers/Index";
import * as actions from "store/actions";

const AllQuestionAnswers = ({ dispatch, todoId, formName, submittedOn, userProfile }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [todoDetails, setTodoDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isOpen && !todoDetails) {
      getTodoDetails();
    }
  }, [isOpen])

  const getTodoDetails = async () => {
    if (!todoId) {
      return;
    }
    try {
      let data = {
        todoId,
      };
      setIsLoading(true);
      const response = await getTodoResponse(data);
      if (response.success === true) {
        const details = response.body;
        setTodoDetails(details);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  }

  const showLoader = () => {
    dispatch(actions.persist_store({ loader: true }));
  }

  const hideLoader = () => {
    dispatch(actions.persist_store({ loader: false }));
  }

  return (
    <Accordion.Item
      eventKey={todoId}
      className=" my-2"
      style={{
        border: "1px solid #2B3674",
      }}
    >
      <Accordion.Header
        className=""
        style={{
          background: "#F9FAFF",
          // border: "1px solid #2B3674",
          color: "#2B3674",
        }}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <span className="toggleBtn position-absolute"></span>

        <div className="d-flex align-items-center gap-10">
          <span className="icn me-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="18"
              viewBox="0 0 17 18"
              fill="none"
            >
              <path
                d="M0.533325 11.8246C0.533325 10.5925 1.53211 9.59375 2.76418 9.59375C3.99624 9.59375 4.99503 10.5925 4.99503 11.8246V17.0503H0.533325V11.8246Z"
                fill="currentColor"
              />
              <path
                d="M6.13538 2.8246C6.13538 1.59254 7.13416 0.59375 8.36623 0.59375C9.59829 0.59375 10.5971 1.59254 10.5971 2.8246V17.0503H6.13538V2.8246Z"
                fill="currentColor"
              />
              <path
                d="M11.7371 7.8246C11.7371 6.59254 12.7358 5.59375 13.9679 5.59375C15.2 5.59375 16.1988 6.59254 16.1988 7.8246V17.0503H11.7371V7.8246Z"
                fill="currentColor"
              />
            </svg>
          </span>
          {formName}
        </div>
        <div className="right d-flex align-items-center gap-10">
          <p className="m-0 text-dark text-lowercase fw-sbold">
            {submittedOn && `Submitted on ${moment(submittedOn).format("MMM DD, YYYY")}`}
          </p>
          {/* <span className="icn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_0_3353)">
                      <path
                        d="M14.5024 2.16927C16.2504 2.16927 17.6719 3.58952 17.6719 5.33594V14.8359C17.6719 16.5824 16.2504 18.0026 14.5024 18.0026H4.99379C3.2458 18.0026 1.82427 16.5824 1.82427 14.8359V5.33594C1.82427 3.58952 3.2458 2.16927 4.99379 2.16927H14.5024ZM14.5024 0.585938H4.99379C3.73288 0.585938 2.52361 1.08638 1.632 1.97718C0.740399 2.86798 0.239502 4.07616 0.239502 5.33594L0.239502 14.8359C0.239502 16.0957 0.740399 17.3039 1.632 18.1947C2.52361 19.0855 3.73288 19.5859 4.99379 19.5859H14.5024C15.7633 19.5859 16.9726 19.0855 17.8642 18.1947C18.7558 17.3039 19.2567 16.0957 19.2567 14.8359V5.33594C19.2567 4.07616 18.7558 2.86798 17.8642 1.97718C16.9726 1.08638 15.7633 0.585938 14.5024 0.585938Z"
                        fill="black"
                      />
                      <path
                        d="M4.99377 10.0846C4.99377 9.87467 5.07726 9.67331 5.22586 9.52484C5.37446 9.37638 5.576 9.29297 5.78616 9.29297H13.71C13.9201 9.29297 14.1217 9.37638 14.2703 9.52484C14.4189 9.67331 14.5024 9.87467 14.5024 10.0846C14.5024 10.2946 14.4189 10.496 14.2703 10.6444C14.1217 10.7929 13.9201 10.8763 13.71 10.8763H5.78616C5.576 10.8763 5.37446 10.7929 5.22586 10.6444C5.07726 10.496 4.99377 10.2946 4.99377 10.0846Z"
                        fill="black"
                      />
                      <path
                        d="M9.74821 14.8398C9.53825 14.8398 9.33688 14.7564 9.18842 14.6078C9.03995 14.4592 8.95654 14.2576 8.95654 14.0475L8.95654 6.12364C8.95654 5.91349 9.03995 5.71194 9.18842 5.56334C9.33688 5.41474 9.53825 5.33126 9.74821 5.33126C9.95817 5.33126 10.1595 5.41474 10.308 5.56334C10.4565 5.71194 10.5399 5.91349 10.5399 6.12364L10.5399 14.0475C10.5399 14.2576 10.4565 14.4592 10.308 14.6078C10.1595 14.7564 9.95817 14.8398 9.74821 14.8398Z"
                        fill="black"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_0_3353">
                        <rect
                          width="19.0172"
                          height="19"
                          fill="white"
                          transform="translate(0.239502 0.585938)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </span> */}
        </div>
      </Accordion.Header>
      <Accordion.Body
        style={{
          borderTop: "1px solid #2B3674",
        }}
      >
        <div className="AccordionInner">
          <div className="py-2">
            <div className="top d-flex align-items-center justify-content-between gap-10">
              <div className="left d-flex align-items-center gap-10">
                
              </div>
              <div className="right">
                <AllQuestionAnswersPDF
                  date={submittedOn}
                  client={userProfile}
                  todoId={todoId}
                  formName={formName}
                  label="Download Report"
                  showLoader={showLoader}
                  hideLoader={hideLoader}
                />
              </div>
            </div>
          </div>
          <div className="inner">
            <div className="py-2">
              {isLoading ?
                <p>Loading...</p>
                :
                todoDetails && todoDetails.length > 0 ?
                  todoDetails.map(page => {
                    return (
                      <>
                        <div className="py-2">
                          <div className="top py-2 commonContent">
                            <h3 className="m-0 py-1 text-uppercase">
                              {decode(page.page_title)}
                            </h3>
                          </div>
                          <div className="py-2">
                            <div className="commonCardBox">
                              <div className="table-responsive">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th className="p-3 text-dark fw-sbold">Question </th>
                                      <th className="p-3 text-dark fw-sbold">Answer </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {page.questions && page.questions.length > 0 &&
                                      page.questions.map(question => {
                                        return (
                                          <tr>
                                            <th className="p-3 ws-normal">
                                              <span className="text-dark fw-sbold">
                                                {decode(question.question)}
                                              </span>
                                            </th>
                                            <td className="p-3 ws-normal">
                                              <RenderAnswerField
                                                question={question}
                                              />
                                            </td>
                                          </tr>
                                        )
                                      })
                                    }
                                    
                                  </tbody>
                                </table>
                              </div>
                              {/* <div className="py-2 px-lg-4 px-3">
                                <p className="m-0 py-2">
                                  My Notes: General Information & Goals (not visible to
                                  clients)
                                </p>
                                <Button
                                  className="border-0 p-0 text-dark d-flex align-items-center underline btnSm"
                                  variant="transparent"
                                >
                                  <span className="icn me-2">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="21"
                                      height="19"
                                      viewBox="0 0 21 19"
                                      fill="none"
                                    >
                                      <path
                                        d="M17.891 0.820312H3.61682C2.12441 0.820312 0.910156 2.03432 0.910156 3.52697V12.2625C0.910156 13.7518 2.11909 14.9639 3.60722 14.9692V18.9332L9.30401 14.9691H17.891C19.3834 14.9691 20.5976 13.7549 20.5976 12.2625V3.52697C20.5976 2.03432 19.3834 0.820312 17.891 0.820312ZM19.4441 12.2624C19.4441 13.1187 18.7474 13.8156 17.891 13.8156H8.9421L4.76078 16.7252V13.8156H3.61682C2.76041 13.8156 2.06372 13.1188 2.06372 12.2625V3.52697C2.06372 2.67048 2.76041 1.97388 3.61682 1.97388H17.891C18.7474 1.97388 19.4441 2.67048 19.4441 3.52697V12.2624Z"
                                        fill="#2B3674"
                                      />
                                      <path
                                        d="M6.17944 4.89648H15.3284V6.05005H6.17944V4.89648ZM6.17944 7.35742H15.3284V8.51098H6.17944V7.35742ZM6.17944 9.81836H15.3284V10.9719H6.17944V9.81836Z"
                                        fill="#2B3674"
                                      />
                                    </svg>
                                  </span>
                                  Add Notes
                                </Button>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  })
                  :
                  <p>Data not available</p>
              }
            </div>
          </div>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  )
}

const mapStateToPros = (state) => {
  return {
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AllQuestionAnswers);