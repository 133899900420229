import { GET_BASIC_SETTINGS, GET_DASHBOARD_DATA } from "store/actions/app";

const initialState = {
  dashboard: {},
  basicSettings: {},
};

//get 
const getDashboardData = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
      callback(payload);
  }
  
  const stateObj = {
      ...state,
      dashboard: data.body,
      loading: false,
  };
  return stateObj;
};

// set basic settings state
const getBasicSettings = (state, payload) => {
  const { data, callback } = payload;
  const stateObj = {
    ...state,
    basicSettings: data?.body,
  };
  return stateObj;
}

const App = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case GET_DASHBOARD_DATA:
          return getDashboardData(state, payload);
        
        case GET_BASIC_SETTINGS:
          return getBasicSettings(state, payload);

        default:
        return state;
    };
}
export default App;