import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { pdf } from "@react-pdf/renderer";

// import { getClientRecommendationReportsList, getRecommendationReportDetails } from "store/services/clientService";
import * as actions from "store/actions";

import SBReportPdf from "./SBReportPdf";
import { getNAQv1Reports } from "store/services/todoService";
import { blobToBase64, validateConditions } from "helpers/common";
import NAQRecommReportPDF from "./NAQRecommReportPDF";
import SeverityPartOnePDF from "./SeverityPartOnePDF";
import SeverityPartTwoPDF from "./SeverityPartTwoPDF";
import { MyDoc as NAQQuestionAnswersPDF } from "./NAQQuestionAnswersPDF";
import { LineChart } from "./SymptomBurdenGraph/LineChart";
import { MyDoc as SymptomBurdenGraph } from "./SymptomBurdenGraph/SBLineGraphPDF";
import moment from "moment";

const NAQ_COMPONENTS = [
  "Symptom Burden Graph",
  "Symptom Burden Report",
  "Recommendation Report",
  "Severity Report Part I",
  "Severity Report Part II",
  "Questions/Answers",
];

const GenerateNAQv1ReportsPdf = forwardRef(function ReportsNAQv1(
  { todoId, client, todoFormDetails, submitReports, getReports },
  ref
) {
  const [chartData, setChartData] = useState(null);
  const [reportsData, setReportsData] = useState(null);
  const [reportsGenerated, setReportsGenerated] = useState(false);
  const [generatingReports, setGeneratingReports] = useState(false);
  const [renderTries, setRenderTries] = useState(0);

  let dateToday = moment();

  const chartRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    async function init() {
      try {
        dispatch(actions.persist_store({ loader: true }));
        let response = await getReports(todoId);
        if (response?.success === true && response?.body) {
          let naqv1Reports = response.body;
          setReportsData(naqv1Reports);
          setChartData(response?.body?.symptomBurdenGraph || {});
        }
        dispatch(actions.persist_store({ loader: false }));
      } catch (err) {
        console.log("Error generating combined PDF", err);
        dispatch(actions.persist_store({ loader: false }));
      }
    }
    init();
  }, [])

  // useImperativeHandle(ref, () => {
  //   return {
  //     async getReports(todoId, formDetails) {
  //       try {
  //         // setLoading(true);
  //         let response = await getNAQv1Reports(todoId);
  //         // let response = JSON.parse(localStorage.getItem("naqReportsTemp") || {});
          
  //         // if (chartRef.current) {
  //           //   chartRef.current.downloadChartImage();
  //           // }
            
  //           // localStorage.setItem("naqReportsTemp", JSON.stringify(response));
  //           if (response?.success === true && response?.body) {
  //             let naqv1Reports = response.body;
  //             setReportsData(naqv1Reports);
  //             setChartData(response?.body?.symptomBurdenGraph || {});
  //             let naqBlobs = await getNAQv1ComponentBlobs(naqv1Reports, formDetails);
  //             return naqBlobs;
  //           }
  //         // console.log("naqBlobs")
  //         // return naqBlobs;
  //         // setLoading(false);
  //       } catch (err) {
  //         console.log("Error generating combined PDF", err);
  //         // setLoading(false);
  //       }
  //     },
  //   };
  // });

  useEffect(async () => {
    async function generateReports() {
      try {
        if(!reportsData || reportsGenerated || generatingReports) {
          return;
        }
        if (chartRef.current && chartRef.current.downloadChartImage) {
            dispatch(actions.persist_store({ loader: true }));
            setGeneratingReports(true);
            let naqBlobs = await getNAQv1ComponentBlobs(reportsData, todoFormDetails);
            setReportsGenerated(true);
            setGeneratingReports(false);
            await submitReports(naqBlobs);
            dispatch(actions.persist_store({ loader: false }));
        } else {
          if(renderTries < 10) {
            setRenderTries(renderTries + 1);
          }
        }
      } catch(err) {
        console.log("Error generating and submitting reports", err);
        dispatch(actions.persist_store({ loader: false }));
        setGeneratingReports(false);
      }
    }

    generateReports();
  }, [chartRef.current, reportsData, chartData]);

  /**
   * Function to generate the PDFs for selected NAQ Components
   */
  const getNAQv1ComponentBlobs = async (naqv1Reports, todoFormDetails) => {
    try {
      const blobs = {};

      /**
       * Generate PDF for SB Graph
       */
      let sbGraphPdf = await sbGrpah(todoFormDetails);
      // console.log("sbReportPdf", sbReportPdf);
      blobs.sbGraphPdf = sbGraphPdf;

      /**
       * Generate PDF for SB Report
       */
      let sbReportPdf = await sbReport(naqv1Reports?.symptomBurdenReport, todoFormDetails);
      // console.log("sbReportPdf", sbReportPdf);
      blobs.sbReportPdf = sbReportPdf;

      /**
       * Generate PDF for NAQ Recommendation
       */
      let recommendationPdf = await recommendationReport(
        naqv1Reports?.recommendationReport,
        todoFormDetails
      );
      // console.log("recommendationPdf", recommendationPdf);
      blobs.recommendationPdf = recommendationPdf;

      /**
       * Generate PDF for Severity Report Part I
       */
      let severityReportPart1Pdf = await severityReportPart1(
        naqv1Reports?.severityReport,
        todoFormDetails
      );
      // console.log("severityReportPart1Pdf", severityReportPart1Pdf);
      blobs.severityReportPart1Pdf = severityReportPart1Pdf;

      /**
       * Generate PDF for Severity Report Part II
       */
      let severityReportPart2Pdf = await severityReportPart2(
        naqv1Reports?.severityReport,
        todoFormDetails
      );
      // console.log("severityReportPart2Pdf", severityReportPart2Pdf);
      blobs.severityReportPart2Pdf = severityReportPart2Pdf;

      /**
       * Generate PDF for NAQ All Questions/Answers with scores
       */
      let naqQueAnsPdf = await naqQueAns(naqv1Reports?.allQuestionAnswers, todoFormDetails);
      // console.log("naqQueAnsPdf", naqQueAnsPdf);
      blobs.naqQueAnsPdf = naqQueAnsPdf;

      return blobs;
    } catch (err) {
      console.log("Error generating NAQ component PDF", err);
      return null;
    }
  };

  async function sbGrpah(todoFormDetails) {
    const base64Image = chartRef.current && chartRef.current.downloadChartImage();
    console.log("base64Image", base64Image);
    const doc = (
      <SymptomBurdenGraph
        base64Image={base64Image}
        client={client}
        date={dateToday}
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    // const blob = await chartRef.current.downloadChartImage();
    return blobToBase64(blob);
  }

  async function sbReport(symptomBurdernData, todoFormDetails) {
    // console.log("symptomBurdernData", symptomBurdernData);

    if (!symptomBurdernData) {
      return null;
    }
    let totalSymptomBurden = 0;
    symptomBurdernData.potential_conditions.map((condition) => {
      if (condition.conditionScore) {
        totalSymptomBurden += condition.conditionScore;
      }
    });
    symptomBurdernData.potential_nutritional_deficiencies.map((deficiency) => {
      if (deficiency.conditionScore) {
        totalSymptomBurden += deficiency.conditionScore;
      }
    });
    // console.log(
    //   "symptomBurdernData",
    //   symptomBurdernData,
    //   totalSymptomBurden,
    //   client,
    //   todoFormDetails.updated_at
    // );
    const doc = (
      <SBReportPdf
        symptomBurdernData={symptomBurdernData}
        totalSymptomBurden={totalSymptomBurden}
        client={client}
        date={dateToday}
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    return blobToBase64(blob);
  }

  async function recommendationReport(recommendationData, todoFormDetails) {
    if (!recommendationData) {
      return null;
    }
    let manufacturer = "generic";
    let nutritionalRows = recommendationData && recommendationData[manufacturer];
    const doc = (
      <NAQRecommReportPDF
        nutritionalRows={nutritionalRows}
        manufacturer={manufacturer}
        client={client}
        date={dateToday}
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    return blobToBase64(blob);
  }

  async function severityReportPart1(priorityReport, todoFormDetails) {
    if (!priorityReport) {
      return null;
    }

    const doc = (
      <SeverityPartOnePDF
        priorityReport={priorityReport?.part1 || []}
        notes={[]}
        client={client}
        date={dateToday}
      />
    );
    const asPdf = pdf([]); // {} is important, throws without an argument
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    return blobToBase64(blob);
  }

  async function severityReportPart2(priorityReport, todoFormDetails) {
    if (!priorityReport) {
      return null;
    }
    const doc = (
      <SeverityPartTwoPDF
        priorityReport={priorityReport?.part2 || []}
        notes={[]}
        client={client}
        date={dateToday}
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    return blobToBase64(blob);
  }

  async function naqQueAns(todoDetails, todoFormDetails) {
    const formattedData = validateConditions(todoDetails);
    const doc = (
      <NAQQuestionAnswersPDF
        client={client}
        date={dateToday}
        todoDetails={formattedData}
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    return blobToBase64(blob);
  }

  // blobs = blobs.filter(blob => !!blob);

  // if(blobs.length == 0) {
  //   return null;
  // }

  // let combinedNAQBlobs = await combineBlobs(blobs);
  // return combinedNAQBlobs;

  return (
    <>
      <div style={{ position: "fixed", right: "-99999px", width: "1000px", height: "1000px" }}>
        {/* <LineChartPDF
          innerRef={chartRef}
          todoId={selectedNAQs && selectedNAQs[0]?.id}
          client={client}
          setLoading={setLoading}
        /> */}
        {chartData && <LineChart ref={chartRef} chartData={chartData} />}
      </div>
    </>
  );
});

export default GenerateNAQv1ReportsPdf;
