import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import { HEALTH_COATCH_CAT_ID } from "common/constants";

const AccountDetails = ({ data, editProfile }) => {
  return (
    <>
      <div className="top py-2">
        <h3 className="m-0 py-1 fw-bold">Account Information</h3>
      </div>
      <div className="TabBody py-3">
        <div className="info">
          <div className="py-2">
            <p className="text-bold m-0 text-dark fw-bold text-uppercase">
              Name
            </p>
            <p className="tex-muted m-0">{userFullName(data.first_name, data.last_name)}</p>
          </div>
          <div className="py-2">
            <p className="text-bold m-0 text-dark fw-bold text-uppercase">
              Login Email
            </p>
            <p className="tex-muted m-0">{data.email}</p>
          </div>
          <div className="py-2">
            <p className="text-bold m-0 text-dark fw-bold text-uppercase">
              Password Last Updated On
            </p>
            <p className="tex-muted m-0">{moment(data.last_password_updated).format('MMM D, YYYY H:ss A')}</p>
          </div>
          <div className="py-2">
            <p className="text-bold m-0 text-dark fw-bold text-uppercase">
              Account ID
            </p>
            <p className="tex-muted m-0">
              {data.uuid}
            </p>
          </div>
          <div className="py-2">
            <p className="text-bold m-0 text-dark fw-bold text-uppercase">
              {data?.user_category === HEALTH_COATCH_CAT_ID ? "Health Coach Name" : "Practitioner Name"}
            </p>
            <p className="tex-muted m-0">{data?.practitioner_name}</p>
          </div>
          <div className="py-2">
            <Link
              onClick={editProfile}
              className=" fw-sbold text-uppercase underline"
            >
              <i>Update log in email or password</i>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

const userFullName = (firstName, lastName) => {
  let output = firstName;
  if(lastName) {
    output = output + " " + lastName;
  }
  return output;
}

export default AccountDetails