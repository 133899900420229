import parse from 'html-react-parser';
import { ReactComponent as OTick } from "Assets/Images/icn/OrangeTick.svg";

const Disclaimer = ({ question }) => {
  let fieldData = question.other_column_data;
  let answer = question.answer && JSON.parse(question.answer);
  let agreed = answer?.agreed;
  let typedName = answer?.full_name;
  let typedDate = answer?.date;

  if(!agreed) {
    return <p><i>Nothing Entered</i></p>
  }

  return (
    <>
      {fieldData?.disclaimer_title && (
        <p className="m-0">
          <strong>Disclaimer Title:</strong> {fieldData?.disclaimer_title}
        </p>
      )}
      {fieldData?.disclaimer_text && (
        <p className="m-0">
          <strong>Disclaimer Content:</strong>
          <br></br>
          {parse(fieldData?.disclaimer_text)}
        </p>
      )}
      <p className="m-0">
        {agreed &&
          <OTick />
        }
        {` `}
        {fieldData && fieldData?.checkbox_text}
      </p>
      {fieldData && fieldData.require_name_date &&
        <>
          <p className="m-0">
            <strong>Typed Name:</strong> {typedName}
          </p>
          <p className="m-0">
          <strong>Typed Date:</strong> {typedDate}
          </p>
        </>
      }
    </>
  )
}

export default Disclaimer;