export const MAIN_PATH = `${process.env.REACT_APP_API_MAIN_PATH}`
export const COMMON_PATH = 'common'
export const APP_PATH = 'api'

// auth
export const LOGIN = "/auth/login";
export const REGISTER = "/auth/register";
export const LOGOUT = "/auth/logout";
export const ACCEPT_INVITE = "/auth/acceptInvite"
export const VALIDATE_SIGNUP_KEY = "/auth/validateSignupKey"
export const VALIDATE_SELF_SIGNUP_KEY = "/auth/validateSelfSignupKey"
export const GET_USER_BY_UUID = "/packages/user";
export const GET_PRACTITIONER_DETAILS = "/auth/practitionerDetails"
export const GET_PRACTITIONER_SETTINGS = "/setting/practitioner"
export const GET_AUTH_PROFILES_LIST = "/auth/profile/list";
export const GET_AUTH_PROFILE_DETAILS = "/auth/profile/switch/";
export const ACCEPT_INVITE_LOGIN = "/auth/acceptInvite/password"

// dashboard
export const GET_DASHBOARD_DATA = "/dashboard/list";

// profile
export const PROFILE = "/auth/me";
export const UPDATE_PROFILE = "/edit_profile";

// account settings
export const ACCOUNT_SETTING_LIST = "/setting";
export const ADD_UPDATE_ACCOUNT_SETTING = "/setting/update/";

// forgot password
export const FORGOT_PASSWORD = "/auth/forgotPassword";

// reset password
export const RESET_PASSWORD_FORM = "/auth/resetPassword";
export const RESET_PASSWORD = "/reset_password";
export const VALIDATE_RESET_PWD_TOKEN = "/auth/validateResetPasswordToken";

export const ACCOUNT_ACTIVATION = "/activate_account";
// change password
export const CHANGE_PASSWORD = "/auth/changePassword"

// Change email
export const UPDATE_EMAIL = "/auth/updateLoginEmail"

// dashboard
export const DASHBOARD = "/dashboard";

// ToDo
export const TODO_LIST = "/todo/list";
export const TODO_VIEW = "/todo/:id/view";
export const SAVE_TODO_RESPONSE = "/todo/:id/saveResponse"
export const GET_TODO_RESPONSE = "/todo/:id/getResponse"
export const SUBMIT_PRACTICE_B_NAQ = "/pb/submit-naq/";

// NAQ v1 reports
export const GET_NAQV1_REPORTS = "/reports/naq1/"
export const GET_NAQV1_REPORTS_PUBLIC = "/reports/public/naq1/"
export const GET_SYMPTOM_BURDEN_GRAPH_V1 = "/reports/naq1/sb-graph/"

// NAQ v2 reports
export const GET_SYMPTOM_BURDEN_GRAPH_V2 = "/reports/naq2/symptom-burden/graph/"
export const GET_FOUNDATION_BURDEN_GRAPH_V2 = "/reports/naq2/foundation-burden/graph/"


// Recommendations
export const RECOMMENDATION_LIST = "/recommendations/";
export const RECOMMENDATION_DETAILS = "/recommendations/";
export const FULLSCRIPT_RECOMMENDATION_LIST = "/recommendations/fullscript/";
export const FULLSCRIPT_RECOMMENDATION_DETAILS = "/recommendations/fullscript/";

// action plans
export const ACTION_PLANS_LIST = "/action-plans/";
export const ACTION_PLANS_DETAILS = "/action-plans/view/";

// terms & conditon accpetances

export const ACCPET_TERMS = "/auth/terms_acceptance";
// export const PLAN_ACTIVE_API = "/profile/plan_active";

// packages
export const GET_CLIENT_PACKAGE_INVITATIONS = "/packages/";
export const GET_CLIENT_PACKAGE = "/packages/active-package";
export const GET_ACTIVE_PACKAGES_LIST = "/packages/active-package/list";
export const GET_PACKAGE_DETAILS = "/packages/:id/details";
export const CREATE_PAYMENT_INTENT = "/packages/:id/payment/create";

// appointments
export const GET_APPOINTMENT_TYPE_LIST = "/appointment_type/list";
export const GET_APPOINTMENT_TYPE_DETAILS = "/appointment_type/";
export const GET_APPOINTMENT_SLOTS = "/appointment/slots/";
export const BOOK_APPOINTMENT = "/appointment/book/";
export const CANCEL_APPOINTMENT = "/appointment/cancel/"
export const CREATE_APPOINTMENT_PAYMENT_INTENT = "/appointment/:id/payment/create";
export const GET_APPOINTMENT_BOOKING_LIST = "/appointment/list";
export const RESCHEDULE_APPOINTMENT = "/appointment/reschedule/";


// public appointment booking
export const PUBLIC_GET_APPOINTMENT_TYPE_LIST = "/appointment/public/calendar/";
// export const PUBLIC_GET_APPOINTMENT_TYPE_DETAILS = "/appointment_type/";
export const PUBLIC_BOOK_APPOINTMENT = "/appointment/public/book";
export const PUBLIC_CANCEL_APPOINTMENT = "/appointment/public/cancel/"
export const PUBLIC_RESCHEDULE_APPOINTMENT = "/appointment/public/reschedule/";
export const PUBLIC_CREATE_APPOINTMENT_PAYMENT_INTENT = "/appointment/public/payment/create/";
export const PUBLIC_SCHEDULER_SETTINGS = "/appointment/public/setting/"
export const PUBLIC_APPT_BOOKING_DETAILS = "/appointment/public/view/"

// Food & Mood Journal
export const SAVE_FOOD_MOOD_JOURNAL_DATA = "/todo/food-log/response";
export const GET_FOOD_MOOD_JOURNAL_DATA = "/todo/food-log/view";
export const GET_FOOD_MOOD_JOURNAL_PROGRESS = "/todo/food-log/state/"
export const SUBMIT_FOOD_MOOD_JOURNAL = "/todo/food-log/submission/";
export const VIEW_FOOD_MOOD_SUBMITTED_DATA = "/todo/food-log/submitted/";
export const DELETE_FMJ_DAY_LOG = "/todo/food-log/remove";

//content pages
export const CONTENT_PAGE_LIST = "/pages/list";
export const CONTENT_PAGE_ADD = "/pages/add";
export const CONTENT_PAGE_UPDATE = "/pages/edit";
export const CONTENT_PAGE_VIEW = "/pages/";
export const CONTENT_PAGE_DELETE = "/pages/delete/";


// Public ToDo
export const PUBLIC_TODO_VIEW = "/todo/public/:id/view";
export const PUBLIC_SAVE_TODO_RESPONSE = "/todo/public/:id/saveResponse"
export const PUBLIC_SUBMIT_TODO = "/todo/public/:id/submit"
// export const PUBLIC_GET_TODO_RESPONSE = "/todo/public:id/getResponse"

// Food & Feeling Logs
export const CREATE_FM_LOG = "/todo/logs/add";
export const UPDATE_FM_LOG = "/todo/logs/edit/";
export const GET_FM_LOG_LIST = "/todo/logs/list"
export const DELETE_FM_LOG = "/todo/logs/remove"

// basic settings
export const FETCH_BASIC_SETTINGS = "/settings/basic";