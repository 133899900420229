import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getToDoList } from "store/services/todoService";
import * as actions from 'store/actions';
import FoodMoodJournalForm from "./Components/FoodMoodJournalForm/Index"
import { FULL_NAME } from "helpers/common";
import { getPractitionerSettings } from "store/services/authService";
import Instructions from "./Components/Instructions";
import { FOOD_MOOD_DIRECTIONS_PDF } from "common/constants";

const MyLog = ({
  dispatch,
  user,
  foodMoodJournal,
  practitionerSettings
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const [directionsRead, setDirectionsRead] = useState(false);

  useEffect(() => {
    async function init() {
      try {
        dispatch(actions.persist_store({ loader: true }));
        await Promise.all([
          dispatch(getPractitionerSettings()),
          dispatch(getToDoList()),
        ])
        dispatch(actions.persist_store({ loader: false }));
      } catch (err) {
        console.log("Error in init", err);
        dispatch(actions.persist_store({ loader: false }));
      }
    }

    init();
  }, [])

  useEffect(() => {
    let directionsRead = localStorage.getItem("fm_directions_read");
    setDirectionsRead(!!directionsRead);
  }, [])

  const onCompleted = () => {
    localStorage.removeItem("fm_directions_read", 1);
    setSubmitted(true);
  }

  const handleClickStart = () => {
    localStorage.setItem("fm_directions_read", 1);
    setDirectionsRead(true);
  }

  if(!practitionerSettings) {
    return "";
  }

  return (
    <section className="p-3">
      <h3 className="m-0 py-1">Food & Mood Journal</h3>
      {isSubmitted ? (
        <p>You have completed your Food & Mood Journal and we have notified {practitionerSettings?.display_name}!</p>
      ) : (
        <>
          {foodMoodJournal && foodMoodJournal.id ? (
            <>
              {/* Check if user has read the directions or not */}
              {!directionsRead ? (
                <>
                  <p>
                    <strong>{practitionerSettings?.display_name}</strong> has asked you to fill out a Food & Mood Journal.
                    Please read the directions below and when you're ready to get started click the 'Start Food & Mood Journal' button at the bottom of the page. You can also view the directions <a target="_blank" href={FOOD_MOOD_DIRECTIONS_PDF}>here</a>.
                  </p>
                  <Instructions
                    onClickStart={handleClickStart}
                  />
                </>
              ) : (
                <FoodMoodJournalForm
                  todoId={foodMoodJournal.id}
                  onToDoCompleted={onCompleted}
                />
              )}
            </>
          ) : (
            <>
              {/* If no Food Mood Journal is assigned by the practitioner */}
              {practitionerSettings && (
                <p>
                  If <strong>{practitionerSettings?.display_name}</strong> asks you to fill out a Food & Mood Journal this is the page you'll use to fill it out.
                  At the moment <strong>{practitionerSettings?.display_name}</strong> hasn't assigned a Food & Mood Journal for you to fill out yet.
                  If they do, we'll let you know!
                </p>
              )}
            </>
          )}
        </>
      )
      }

    </section>
  );
};

const mapStateToPros = (state) => {
  let foodMoodJournal = state.ToDo?.items?.pending?.find((todo) => todo.form_id === 21 && todo.status !== "completed");
  return {
    user: state.Auth.user,
    practitionerSettings: state.Auth.practitionerSettings,
    foodMoodJournal
  }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(MyLog);
