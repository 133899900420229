import React, { useEffect, useState } from "react";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";

// icn
import { ReactComponent as WPlus } from "../../../Assets/Images/icn/plus.svg";
import { ReactComponent as UserInfo } from "../../../Assets/Images/icn/userInfo.svg";
import { ReactComponent as SubsInfo } from "../../../Assets/Images/icn/Subscription.svg";
import { ReactComponent as SettingIcn } from "../../../Assets/Images/icn/setting.svg";
import { ReactComponent as NotifiSetting } from "../../../Assets/Images/icn/NotifiSetting.svg";
import { ReactComponent as PackageIcn } from "../../../Assets/Images/icn/Package.svg";
import { ReactComponent as TagIcn } from "../../../Assets/Images/icn/Tag.svg";
import { ReactComponent as TemplateIcn } from "../../../Assets/Images/icn/Template.svg";
import { ReactComponent as PuzzleIcn } from "../../../Assets/Images/icn/puzzle.svg";
import { ReactComponent as EditIcn } from "../../../Assets/Images/icn/Edit.svg";
import { ReactComponent as WebIcn } from "../../../Assets/Images/icn/web.svg";
import { ReactComponent as TermsIcn } from "../../../Assets/Images/icn/info.svg";

// css
import "./MyAccount.scss";
import AccountInfo from "./Component/TabContent/AccountInformation/Index";
import SubscriptionInfo from "./Component/TabContent/Subscription/Index";
import AccountSetting from "./Component/TabContent/AccountSetting/Index";
import NotificationSetting from "./Component/TabContent/NotificationSetting/Index";
import MyPackage from "./Component/TabContent/MyPackage/Index";
import MyTags from "./Component/TabContent/MyTags/Index";
import MyTemplate from "./Component/TabContent/MyTemplate/Index";
import ClientSelfRegisteration from "./Component/TabContent/ClientSelfRegisteration/Index";
import OldSite from "./Component/TabContent/OldSite/Index";
import TermsCondition from "./Component/TabContent/Terms&Condition/Index";
import ThirdPartyIntegeration from "./Component/TabContent/ThirdPartyIntegeration/Index";

import { profile } from "store/services/profileService";
import { getAccountSettingList } from "store/services/accountSettingService";
import * as actions from "store/actions";

const MyAccount = ({ dispatch, loading, profileDetails, accountSettingList }) => {
  const [activeTab, setActiveTab] = useState();
  const location = useLocation();
  const [successResponse, setSuccessResponse] = useState(false);
  // const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [sending, setSending] = useState(false);
  const [currentTabValue, setCurrentTabValue] = useState(null);
  //client registration
  const [selfCheckbox1, setSelfCheckbox1] = useState([]);
  const [selfCheckbox2, setSelfCheckbox2] = useState([]);

  useEffect(() => {
    let params = new URLSearchParams(location.search);
    let activeTab = params.get("tab");
    if(activeTab) {
      setActiveTab(activeTab);
    }
  }, []);

  //get setting data
  async function getData() {
    try {
      let res = await dispatch(getAccountSettingList());
      // if (res?.body?.length > 0) {
      //   let defaultFormsValue = [];
      //   for (let val of res?.body) {
      //     if (val.data_key === "default_forms") {
      //       let data_set = JSON.parse(val.data_set);
      //       for (let j of data_set) {
      //         if (!defaultFormsValue.includes(j)) {
      //           defaultFormsValue.push(j);
      //         }
      //       }
      //     }
      //   }
      //   setSelectedCheckboxes(defaultFormsValue);
      // }
    } catch (err) {
      console.log(err);
    }
  }
  //get default form list api
  // async function getDefautForm() {
  //   try {
  //     setCurrentTabValue(null);
  //     await dispatch(getDefaultFormList_());
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }
  useEffect(() => {
    getData();
    // getDefautForm();
    if (successResponse) {
      getData();
    }
    setTimeout(() => {
      setShowMessage(false);
    }, 1000);
  }, [successResponse]);

  useEffect(() => {
    async function init() {
      try {
        dispatch(actions.persist_store({ loader: true }));
        dispatch(profile());
        dispatch(actions.persist_store({ loader: false }));
      } catch(err) {
        console.log("Error fetching account data", err);
        dispatch(actions.persist_store({ loader: false }));
      }
    }

    init();
  }, []);

  return (
    <>
      <section className="myAccount position-relative">
        <Container>
          <Row>
            <Col lg="12" className="my-2">
              <div className="commonCardBox">
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="AccountInfo"
                >
                  <Row className="commonTabsSec">
                    <Col sm={3} className="">
                      <div className="TabLink h-100 border-end">
                        {/* <div className="top border-bottom p-3 ps-lg-4 py-lg-4">
                          <h3 className="fw-sbold m-0 py-1 theme-clr2">
                            NTA Academics Team
                          </h3>
                          <p className="text-muted m-0">
                            academics@nutritionaltherapy.com
                          </p>
                        </div> */}
                        <div className="TabsWrp">
                          <Nav variant="pills" className="flex-column py-3">
                            <Nav.Item>
                              <Nav.Link
                                className="bg-transparent borderd-0 text-dark px-lg-4 d-flex align-items-center fw-sbold"
                                eventKey="AccountInfo"
                              >
                                <span className="icn me-2">
                                  <UserInfo />
                                </span>
                                Account Information
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                className="bg-transparent borderd-0 text-dark px-lg-4 d-flex align-items-center fw-sbold"
                                eventKey="NotificationSetting"
                              >
                                <span className="icn me-2">
                                  <NotifiSetting />
                                </span>
                                Notification Settings
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                className="bg-transparent borderd-0 text-dark px-lg-4  d-flex align-items-center fw-sbold"
                                eventKey="MyPackage"
                              >
                                <span className="icn me-2 stroke">
                                  <PackageIcn />
                                </span>
                                My Packages
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                className="bg-transparent borderd-0 text-dark px-lg-4 d-flex align-items-center fw-sbold"
                                eventKey="TandC"
                              >
                                <span className="icn me-2">
                                  <TermsIcn />
                                </span>
                                Terms and Conditions
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </div>
                      </div>
                    </Col>
                    <Col sm={9}>
                      <Tab.Content className="py-3 px-3">
                        <Tab.Pane eventKey="AccountInfo">
                          <AccountInfo profile={profileDetails} loading={loading} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="Subscription">
                          <SubscriptionInfo />
                        </Tab.Pane>
                        <Tab.Pane eventKey="AccountSetting">
                          <AccountSetting />
                        </Tab.Pane>
                        <Tab.Pane eventKey="NotificationSetting">
                          {/* <NotificationSetting accountSettingList={accountSettingList}/> */}
                          <NotificationSetting
                            accountSettingList={accountSettingList}
                            sending={sending}
                            setSending={setSending}
                            currentTabValue={currentTabValue}
                            setCurrentTabValue={setCurrentTabValue}
                            setSuccessResponse={setSuccessResponse}
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="MyPackage">
                          <MyPackage />
                        </Tab.Pane>
                        <Tab.Pane eventKey="MyTags">
                          <MyTags />
                        </Tab.Pane>
                        <Tab.Pane eventKey="MyTemplates">
                          <MyTemplate />
                        </Tab.Pane>
                        <Tab.Pane eventKey="3rdParty">
                          <ThirdPartyIntegeration />
                        </Tab.Pane>
                        <Tab.Pane eventKey="clientSelfRegisteration">
                          <ClientSelfRegisteration />
                        </Tab.Pane>
                        <Tab.Pane eventKey="OldSite">
                          <OldSite />
                        </Tab.Pane>
                        <Tab.Pane eventKey="TandC">
                          <TermsCondition />
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    loading: state.Profile.loading,
    profileDetails: state.Profile.user,
    accountSettingList: state.AccountSetting.accountSettingList,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(MyAccount);