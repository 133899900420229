import React, { useEffect } from "react";
import { lazy, Suspense } from "react";
import { connect } from "react-redux";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//imported
import PrivateRoute from "./PrivateRoute";

import "../Assets/css/style.css";
import * as Path from "./paths";
import Login from "pages/AuthPages/Login/Index";
import ForgotPassword from "pages/AuthPages/ForgotPassword/Index";
import AuthLayout from "layout/AuthLayout/AuthLayout";
import NonAuthLayout from "layout/NonAuthLayout/NonauthLayout";
import PublicLayout from "layout/PublicLayout/PublicLayout";
import Dashboard from "pages/SideTabs/Dashboard/Index";
import SignUp from "pages/AuthPages/SignUp/Index";
import SelectProfile from "pages/AuthPages/SelectProfile/Index";
import Research from "pages/SideTabs/Research/Index";
import MyAccount from "pages/SideTabs/MyAccount/Index";
import Recommendation from "pages/SideTabs/Recommendation/Index";
import ToDo from "pages/SideTabs/ToDo/Index";
import TodoForms from "pages/SideTabs/ToDo/Component/TodoForm/Index";
import MyLog from "pages/SideTabs/MyLog/Index";
import ResetPassword from "pages/AuthPages/ResetPassword/Index";
import BookingMeetingForm from "pages/Appointments/BookMeetingSlot/Index";
import PurchasePackage from "pages/PurchasePackage/Payment/Index";
import PackagePaymentSuccess from "pages/PurchasePackage/PaymentSuccess/Index";
import AppointmentPaymentSuccess from "pages/Appointments/BookMeetingSlot/PaymentSuccess/Index";
import ViewAppointment from "pages/Appointments/ViewAppointment";
import TermsCondition from "pages/SideTabs/TermsCondition/Index";
import TermsConditions from "pages/SideTabs/TermsConditions/Index";
import PrivacyPolicy from "pages/SideTabs/PrivacyPolicy/Index";
import PrivacyPolicyAgreement from "pages/SideTabs/PrivaryPolicyAgreement/Index";
import PublicTodoForm from "pages/publicTodoForm/Index";
import LogMeal from "pages/SideTabs/AnytimeLogging/LogMeal/Index";
import LogFeeling from "pages/SideTabs/AnytimeLogging/LogFeeling/Index";
import ViewLog from "pages/SideTabs/AnytimeLogging/LogList/Index";
import SwitchProfile from "pages/SideTabs/SwitchProfile/Index";

// const Appointment = lazy(() => import("./pages/SideTabs/Appointment/Index"));

function routes({ isAuthenticated }) {

  return (
    <>
      {/* <Suspense fallback={<div>Loading...</div>}> */}
      <Routes>
        <Route element={isAuthenticated ? <NonAuthLayout /> : <AuthLayout />}>
          {isAuthenticated ? (
            <>
              <Route index element={<Navigate to={Path.dashboard} />} />

              <Route path={Path.dashboard} element={<Dashboard />} />
              <Route path={Path.foodResearch} element={<Research />} />
              <Route path={Path.myAccount} element={<MyAccount />} />
              <Route path={Path.recommendation} element={<Recommendation />} />
              <Route path={Path.actionPlans} element={<Recommendation />} />
              <Route path={Path.todo} element={<ToDo />} />
              <Route path={Path.viewTodo} element={<TodoForms />} />
              <Route path={Path.myLog} element={<MyLog />} />
              <Route path={Path.logMeal} element={<LogMeal />} />
              <Route path={Path.logFeeling} element={<LogFeeling />} />
              <Route path={Path.viewLog} element={<ViewLog />} />
              <Route path={Path.switchProfileDashboard} element={<SwitchProfile />} />
              {/* <Route path={Path.purchasePackage} element={<PurchasePackage />} />
              <Route path={Path.purchasePackageSuccess} element={<PackagePaymentSuccess />} /> */}
              {/* <Route
                path={Path.dashboardTermsCondition}
                element={<TermsCondition />}
              /> */}
              <Route path={Path.privacyPolicyAgreement} element={<PrivacyPolicyAgreement />} />
              <Route path={Path.privacyPolicy} element={<PrivacyPolicy />} />
              <Route
                path={Path.termsConditions}
                element={<TermsConditions />}
              />

              {/* Default route */}
              <Route path="*" element={<Navigate to={Path.dashboard} />} /> 
            </>
          ) : (
            <>
              <Route path={Path.login} element={<Login />} />
              <Route path={Path.signup} element={<SignUp />} />
              <Route path={Path.selectProfile} element={<SelectProfile />} />
              <Route path={Path.forgotPassword} element={<ForgotPassword />} />
              <Route
                path={Path.resetPassword}
                element={<ResetPassword />}
              />

              {/* Default route */}
              <Route path="*" element={<Navigate to={Path.login} />} />
            </>
          )}
        </Route>
        <Route element={<PublicLayout />}>
          <Route path={Path.appointmentForm} element={<BookingMeetingForm />} />
          <Route path={Path.appointmentPaymentSuccess} element={<AppointmentPaymentSuccess />} />
          <Route path={Path.viewAppointmentPublic} element={<ViewAppointment />} />
          <Route path={Path.purchasePackage} element={<PurchasePackage />} />
          <Route path={Path.purchasePackageSuccess} element={<PackagePaymentSuccess />} />
          <Route path={Path.publicTodoForm} element={<PublicTodoForm />} />
          <Route path="/*" element={<h1>Page not found:(</h1>} />
        </Route>
      </Routes>
      {/* </Suspense> */}
    </>
  );
}

const mapStateToPros = (state) => {
  return {
    isAuthenticated: state.Auth.isAuthenticated,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(routes);
