import {postData, getData, putData, deleteData} from 'helpers/apiHelper'
import { FETCH_BASIC_SETTINGS, GET_DASHBOARD_DATA, MAIN_PATH  } from './apiPath'
import { basicSettings, dashboardData } from 'store/actions/app';

// get Appointment type list
export function dashboard(callback) {
  let url = `${MAIN_PATH}${GET_DASHBOARD_DATA}`;
  // let url = `http://localhost:3000/dev/dashboard/list`;
  return (dispatch) => getData(url)
    .then((data) => {
      dispatch(dashboardData(data, callback));
      return data;
    });
}

// get basic settings
export function getBasicSettings(callback) {
  let url = `${MAIN_PATH}${FETCH_BASIC_SETTINGS}`;
  return (dispatch) => getData(url)
    .then((data) => {
      dispatch(basicSettings(data, callback));
      return data;
  });
}