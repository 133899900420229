import {postData, getData} from 'helpers/apiHelper'
import { MAIN_PATH, PROFILE, UPDATE_PROFILE, ACCPET_TERMS } from './apiPath'
import { getProfile, updateProfile, accpetPolicy, activePlanStatus} from 'store/actions/profile';
//get profile
export function profile(value, callback) {
  let url = `${MAIN_PATH}${PROFILE}`;
  // let url = `http://localhost:3000/dev/auth/me`;
  return (dispatch) => getData(url)
    .then((data) => {
      dispatch(getProfile(data, callback));
      return data;
    });
}

//update profile
export function updateProfile_(value, callback) {
  return (dispatch) => postData(`${MAIN_PATH}${UPDATE_PROFILE}`, value)
    .then((data) => {
      dispatch(updateProfile(data, callback));
      return data;
    });
}

//accept terms condition
export function accpetPolicy_(value, callback) {
  return (dispatch) => postData(`${MAIN_PATH}${ACCPET_TERMS}`, value)
    .then((data) => {
      dispatch(accpetPolicy(data, callback));
      return data;
    });
}


