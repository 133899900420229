import { SUCCESS_MESSAGE } from "common/constantMessage";
import { UC_FIRST, parseTimeString } from "helpers/common";
import { cloneDeep } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import parse from 'html-react-parser';
import { CSVLink, CSVDownload } from "react-csv";

import * as actions from 'store/actions';
import { deleteFoodOrFeelingLog, getSubmittedLogData } from "store/services/foodMoodJournalService";
import { ReactComponent as EditIcon } from "Assets/Images/icn/pencil.svg";
import EditMealPopup from "./Component/Modals/EditMealPopup";
import { BAD_FEELINGS, FM_BAD_FEELING_REASONS, FM_FEELINGS } from "common/constants";
import { editFeelingLog, editMealLog } from "store/actions/myLog";
import * as Path from "Routes/paths";
import EditFeelingPopup from "./Component/Modals/EditFeelingPopup";

const ViewLog = ({ dispatch }) => {
  const [logList, setLogList] = useState(null);
  const [comments, setComments] = useState(null);

  const flattenLogList = useMemo(() => {
    let allLogs = [];
    for (let date in logList) {
      for (let time in logList[date]) {
        let logs = logList[date][time];
        allLogs = [...allLogs, ...logs];
      }
    }

    return allLogs;
  }, [logList]);

  // search states
  const [searchFieldValue, setSearchFieldValue] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [showSearchScreen, setShowSearchScreen] = useState(false);

  // edit meal states
  const [showEditMealPopup, setShowEditMealPopup] = useState(false);
  const [currentMealLog, setCurrentMealLog] = useState(null);

  // edit feeling states
  const [showEditFeelingPopup, setShowEditFeelingPopup] = useState(false);
  const [currentFeelingLog, setCurrentFeelingLog] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    getSubmittedLogs();
  }, []);

  useEffect(() => {
    if (!searchTerm) {
      cancelSearch();
    }
  }, [searchTerm]);

  const getSearchResults = (flattenLogList, searchTerm) => {
    if (!searchTerm) {
      return null;
    }

    // filter meal logs
    let searchResults = [];
    flattenLogList?.map(log => {
      let matched = false;
      if (log?.log_type == "meal") {

        // find in eaten foods
        let matchedFoods = log?.food_served?.filter(food => food?.eaten?.toLowerCase().includes(searchTerm.toLowerCase()));
        if (matchedFoods && matchedFoods.length > 0) {
          matched = true;
        }

        if (matched) {
          matchedFoods.forEach(food => {
            searchResults.push({
              log_date: log?.log_date,
              search_content: `You ate: <span className="highlight">${UC_FIRST(food?.eaten)}</span>`
            })
          })
        }
      } else if (log?.log_type == "feeling") {

        // find in additional notes
        if (log?.additional_notes?.toLowerCase().includes(searchTerm.toLowerCase())) {
          matched = true;
        }

        if (matched) {
          searchResults.push({
            log_date: log?.log_date,
            search_content: `
                  Feeling: ${log?.feelings} <br>
                  Notes: <span className="highlight">${log?.additional_notes}</span>
                `
          })
        }
      }
    });
    return searchResults
  }


  const searchResults = useMemo(
    () => getSearchResults(flattenLogList, searchTerm),
    [flattenLogList, searchTerm]
  )

  const showSearchResults = () => {
    setShowSearchScreen(true);
    setSearchTerm(searchFieldValue);
  }

  const cancelSearch = () => {
    setSearchTerm("");
    setSearchFieldValue("");
    setShowSearchScreen(false);
  }

  const handleChangeSearchField = (e) => {
    let searchValue = e.target.value;
    if (!searchValue) {
      cancelSearch();
    }
    setSearchFieldValue(searchValue);
  }

  const getSubmittedLogs = async () => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      let response = await getSubmittedLogData();
      // console.log("response", response);
      if (response.success === true) {
        if (response?.body?.log_data) {
          setLogList(response.body.log_data);
        }
        if (response?.body?.comments) {
          setComments(response.body.comments);
        }
      }
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      console.log("Error getting logs list", err);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  const openEditMealPopup = (mealLog) => {
    setCurrentMealLog(mealLog);
    setShowEditMealPopup(true);
  }

  const handleEditMeal = () => {
    dispatch(editMealLog(currentMealLog));
    navigate(Path.logMeal);
  }

  const openEditFeelingPopup = (feelLog) => {
    setCurrentFeelingLog(feelLog);
    setShowEditFeelingPopup(true);
  }

  const handleEditFeeling = () => {
    dispatch(editFeelingLog(currentFeelingLog));
    navigate(Path.logFeeling);
  }

  const handleDeleteLog = async (log) => {
    try {
      let data = {
        log_type: log?.log_type,
        id: log?.id
      }
      dispatch(actions.persist_store({ loader: true }));
      await deleteFoodOrFeelingLog(data);
      await getSubmittedLogs();
      setShowEditFeelingPopup(false);
      setShowEditMealPopup(false);
      dispatch(actions.persist_store({ loader: false }));
    } catch(err) {
      console.log("Error deleting logs", err);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  const downloadCSVContents = () => {
    let csvOutput = [];
    flattenLogList.forEach(log => {
      let logDateTime = `${moment(log?.log_date).format("DD/MM/YYYY")} ${log?.log_time}`
      if (log?.log_type == "feeling") {
        /**
         * return single feeling log
         */
        csvOutput.push({
          logDateTime,
          logType: log?.log_type,
          mealType: "",
          FoodName: "",
          foodServingSize: "",
          feelingName: log?.feelings,
          feelingNotes: log?.additional_notes,
        });
      } else if (log?.log_type == "meal") {
        /**
         * return multiple entries for every food eaten in the log
         */
        if (log?.food_served && log?.food_served.length > 0) {
          log?.food_served?.forEach(food => {
            csvOutput.push({
              logDateTime,
              logType: log?.log_type,
              mealType: log?.meal_type,
              FoodName: food?.eaten,
              foodServingSize: food?.size,
              feelingName: "",
              feelingNotes: "",
            });
          })
        } else {
          csvOutput.push({
            logDateTime,
            logType: log?.log_type,
            mealType: log?.meal_type,
            FoodName: "",
            foodServingSize: "",
            feelingName: "",
            feelingNotes: "",
          });
        }
      }
    })
    return csvOutput;
  }

  const RenderComments = ({ comments }) => {
    return (
      <div class="card">
        <div class="card-header d-flex justify-content-between align-items-center">
          Your Comments
          <Button
            className="commonBtn btn-sm"
          >
            Add client visible comment
          </Button>
        </div>
        <div class="card-body">
          {comments && comments.length > 0 && comments.map(comment => {
            return (
              <div className="single-comment py-2 border-bottom">
                <p className="d-flex justify-content-between">
                  <strong>You</strong>
                  <span>{moment(comment.created_at).format("MMM DD, YYYY h:mm A")}</span>
                </p>
                <p>
                  {comment.comment}
                </p>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <section className="toDo position-relative">
      <EditMealPopup
        showPopup={showEditMealPopup}
        closePopup={() => {
          setShowEditMealPopup(false);
        }}
        currentMeal={currentMealLog}
        editMeal={handleEditMeal}
        deleteMeal={handleDeleteLog}
      />

      <EditFeelingPopup
        showPopup={showEditFeelingPopup}
        closePopup={() => {
          setShowEditFeelingPopup(false);
        }}
        currentFeeling={currentFeelingLog}
        editFeeling={handleEditFeeling}
        deleteFeeling={handleDeleteLog}
      />

      <Container fluid>
        <Row>
          <Col lg="12" className="my-2">
            <h3 className="m-0 py-1">My Log</h3>
          </Col>
          <Col lg="12" className="my-2">
            <div className="d-flex justify-content-between">
              <div className="search-container d-flex gap-10">
                <input
                  type="text"
                  className="form-control w-10"
                  placeholder="Search log..."
                  value={searchFieldValue}
                  onChange={handleChangeSearchField}
                />
                {searchFieldValue && (
                  <div className="buttons-container d-flex gap-10">
                    <Button
                      className="btn text-white commonBtn"
                      onClick={showSearchResults}
                    >
                      Search
                    </Button>
                    <Button
                      className="btn"
                      variant="outlined"
                      onClick={cancelSearch}
                    >
                      Cancel
                    </Button>
                  </div>
                )}
              </div>
              <Button
                className="d-flex btn text-white align-items-center fw-bold justify-content-center commonBtn"
              >
                <CSVLink
                  className="text-white"
                  data={downloadCSVContents()}
                >Export Log</CSVLink>
              </Button>
            </div>
          </Col>
        </Row>
        {showSearchScreen ? (
          <Row>
            <Col lg="12">
              <div className="py-2 d-flex border-bottom align-items-center">
                <h6 className="m-0 py-1">Searching for</h6>
                <span className="px-2 highlight"><strong>{searchTerm}</strong></span>
              </div>
              <div className="py-2">
                <table style={{ width: "100%" }}>
                  {searchResults && searchResults.map(log => {
                    return (
                      <tr className="border-bottom">
                        <td width="40%" className="p-2">{moment(log?.log_date).format("DD/MM/YYYY")}</td>
                        <td width="60%" className="p-2">{parse(log.search_content)}</td>
                      </tr>
                    )
                  })}
                </table>
              </div>
            </Col>
          </Row>
        ) : (
          <>
            {logList && (
              <ErrorBoundary fallback={<Text>(Unable to load log list)</Text>}>
                <Row>
                  <Col lg="12">
                    {Object.keys(logList).map(date => {
                      const logData = logList[date];
                      return (
                        <div className="my-log-list">
                          <div className="py-3 mt-3 border-bottom">
                            <h3 className="m-0 py-1">{date}</h3>
                          </div>
                          <div className="py-2 time-rows px-4">
                            {logData && Object.keys(logData)?.map(time => {
                              const parsedTime = parseTimeString(time);
                              const rowData = logData[time];
                              const commentData = comments?.filter(comment => {
                                let commentDate = moment(comment.log_date).startOf('day');
                                let dateToCompare = moment(date).startOf('day');
                                return dateToCompare.isSame(commentDate) && comment.log_time == time
                              })
                              return (
                                <>
                                  <div className="py-2 border-bottom">
                                    <h6 className="m-0 py-1">{parsedTime.format("hh:mm A")}</h6>
                                  </div>
                                  <div className="log-rows d-flex gap-10" style={{ width: "100%" }}>
                                  <div className="py-2" style={{ width: "50%" }}>
                                    {rowData && rowData.length > 0 && rowData.map(log => {
                                      // console.log("single log", log)
                                      return (
                                        log?.log_type == "meal" ? (
                                          <>
                                            <div className="py-2">
                                              <p className="m-0 fw-sbold d-flex gap-10">
                                                {UC_FIRST(log.meal_type)}{` `}
                                                <Link
                                                  onClick={() => {
                                                    openEditMealPopup(log);
                                                  }}
                                                >
                                                  <EditIcon />
                                                </Link>
                                              </p>
                                            </div>
                                            <div className="px-4">
                                              {log?.food_served && (
                                                <ul style={{ listStyleType: "none", padding: "0" }}>
                                                  {log?.food_served?.map(food => {
                                                    return (
                                                      <li>
                                                        <p className="m-0">
                                                          {/* <Link> */}
                                                          {UC_FIRST(food?.eaten)}{` `}
                                                          {/* </Link> */}
                                                          Serving size: <span>{food?.size}</span>
                                                        </p>
                                                      </li>
                                                    )
                                                  })}
                                                </ul>
                                              )}
                                            </div>
                                          </>
                                        ) : log?.log_type == "feeling" && (
                                          <>
                                            <div className={`feeling-card feeling-${log.feelings.toLowerCase()} my-3`}>
                                              <p className="m-0 fw-sbold d-flex">
                                                Feeling:
                                                <span className="px-1 d-flex gap-10">
                                                  {UC_FIRST(FM_FEELINGS[log?.feelings])}
                                                  <Link
                                                    onClick={() => {
                                                      openEditFeelingPopup(log);
                                                    }}
                                                  >
                                                    <EditIcon />
                                                  </Link>
                                                </span>
                                              </p>
                                              {BAD_FEELINGS.includes(log?.feelings.toLowerCase()) && (
                                                <p className="m-0"><i>Feeling {UC_FIRST(log?.feelings)} because: {FM_BAD_FEELING_REASONS[log?.bad_feelings_reason]}</i></p>
                                              )}
                                              {log?.additional_notes && (
                                                <p className="m-0">{log?.additional_notes}</p>
                                              )}
                                            </div>
                                          </>
                                        )

                                      )
                                    })}
                                  </div>
                                  <div className="comments-container py-2" style={{ width: "50%" }}>
                                      {commentData && commentData.length > 0 && (
                                        <RenderComments
                                          comments={commentData}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </>
                              )
                            })}
                          </div>
                        </div>
                      )
                    })}
                  </Col>
                </Row>
              </ErrorBoundary>
            )}
          </>
        )}
      </Container>
    </section>
  );
};

const mapStateToPros = (state) => {
  // console.log("state", state)
  return {
    user: state.Auth.user,
    practitioner: state.Auth.practitionerDetails,
  }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(ViewLog);
