import React, { useEffect, useState } from "react";
import { Accordion, Button } from "react-bootstrap";
import moment from "moment";
import parse from 'html-react-parser';
import { decode } from "html-entities";
import { connect } from "react-redux";

import { ReactComponent as DownloadIcn } from "Assets/Images/icn/download.svg";

import AllQuestionAnswersPDF from "components/shared/PDFReportComponents/AllQuestionAnswers/Index";
import * as actions from "store/actions";
import { RenderAnswerField } from "components/shared/ToDoQuestionsAnswers/RenderAnswer/Index";

const SingleActionPlan = ({
  dispatch,
  actionPlan,
  getDetails,
  user
}) => {
  // let rows = {};
  // let additionalRecommendation = {};
  // try {
  //   additionalRecommendation = JSON.parse(recommendation.additionalRecommendation);
  //   rows = JSON.parse(recommendation.recommendation);
  // } catch(error) {
  //   console.log("Error parsing JSON data", error);
  // }

  const [actionPlanDetails, setActionPlanDetails] = useState(null);
  const [isLoading, setLoading] = useState(false)
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      fetchActionPlanDetails();
    }
  }, [isOpen])

  const fetchActionPlanDetails = async () => {
    try {
      if (!actionPlan.id) {
        throw new Error("Invalid action plan ID");
      }
      setLoading(true);
      let response = await getDetails(actionPlan.id);
      if (response.success === true && response.body) {
        setActionPlanDetails(response.body);
      }
      setLoading(false);
    } catch (err) {
      console.log("Error occurred while fetching action plan details", err);
      setLoading(false);
    }
  }

  if (!actionPlan) {
    return "";
  }

  return (
    <Accordion.Item
      eventKey={actionPlan.id}
      className="recommendation my-2"
      style={{
        border: "1px solid #405C40",
      }}
    >
      <Accordion.Header
        className=""
        style={{
          background: "#FFFDFA",
          color: "#405C40",
        }}
        onClick={() => {
          setOpen(true)
        }}
      >
        <span className="toggleBtn position-absolute"></span>
        <div className="d-flex align-items-center gap-10">
          Action Plan
        </div>
        <div className="right d-flex align-items-center gap-10">
          <p className="m-0 text-dark text-lowercase fw-sbold">
            {`submmitted on ${moment(actionPlan.updated_at).format("MMM DD, YYYY")}`}
          </p>
        </div>
      </Accordion.Header>
      <Accordion.Body
        style={{
          borderTop: "1px solid #405C40",
        }}
      >
        {isLoading ?
          <p>
            Loading...
          </p>
          :
          actionPlanDetails &&
            <div className="AccordionInner">
              <div className="inner">
                <div className="">
                  <div className="top d-flex align-items-start justify-content-between gap-10 flex-wrap">
                    <div className="left">
                    </div>
                    <div className="right">
                      <AllQuestionAnswersPDF
                        date={actionPlan.updated_at}
                        client={user}
                        todoId={actionPlan.id}
                        formName={"Action Plan"}
                        showColumns={false}
                        label="Download Report"
                        showLoader={() => {
                          dispatch(actions.persist_store({ loader: true }));
                        }}
                        hideLoader={() => {
                          dispatch(actions.persist_store({ loader: false }));
                        }}
                      />
                    </div>
                  </div>
                </div>
                {actionPlanDetails && actionPlanDetails.map(page => {
                  return (
                    <>
                      <div className="py-2">
                        {page.page_title && (
                          <div className="top py-2 commonContent">
                            <h3 className="m-0 py-1 text-uppercase">
                              {page.page_title}
                            </h3>
                          </div>
                        )}
                        <div className="py-2">
                          <div className="commonCardBox">
                            <div className="table-responsive">
                              <table className="table">
                                <tbody>
                                  {page.questions && page.questions.length > 0 &&
                                    page.questions.map((question, i) => {
                                      return (
                                        <tr>
                                          <th width="40%" className="p-3 ws-normal">
                                            <span className="text-dark fw-sbold">
                                              {decode(question.question)}
                                            </span>
                                          </th>
                                          <td width="60%" className="p-3 ws-normal">
                                            {/* {decode(question.answer)} */}
                                            <RenderAnswerField 
                                              question={question}
                                            />
                                          </td>
                                        </tr>
                                      )
                                    })
                                  }

                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                })}
              </div>
            </div>
        }
      </Accordion.Body>
    </Accordion.Item>
  )
}

const mapStateToPros = (state) => {
  return {
  }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(SingleActionPlan);
