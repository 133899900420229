import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { Link, generatePath, useLocation } from "react-router-dom";
import * as Path from "Routes/paths";
import moment from "moment";

const ToDoItem = ({ todo }) => {
  const formatToDoStatus = (status) => {
    let output = status.charAt(0).toUpperCase() + status.slice(1, status.length);
    output = output.replaceAll("_", " ").replaceAll("-", " ");
    return output;
  }
  return (
    <Col lg="6" className="my-2">
      <Link
        to={todo.status === "completed" ? Path.todo : generatePath(Path.viewTodo, {id: todo.todo_id})}
        className="text-dark d-flex align-items-start gap-10 py-1 border p-3 py-3 rounded LinkCard position-relative"
      >
        <span
          className="icn position-absolute"
          style={{ right: "5px", top: "5px" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35"
            height="35"
            viewBox="0 0 35 35"
            fill="none"
          >
            <g clip-path="url(#clip0_0_8238)">
              <path
                d="M11.1044 7.2086C5.42757 10.938 3.84316 18.5908 7.57253 24.2676C11.3019 29.9443 18.9547 31.5288 24.6315 27.7994C30.3083 24.07 31.8927 16.4172 28.1633 10.7404C24.4339 5.06364 16.7811 3.47923 11.1044 7.2086ZM23.786 26.5125C18.8187 29.7758 12.1228 28.3895 8.85945 23.4221C5.59614 18.4547 6.98243 11.7588 11.9498 8.49552C16.9172 5.23221 23.6131 6.6185 26.8764 11.5859C30.1397 16.5532 28.7534 23.2491 23.786 26.5125ZM21.8848 14.2139C21.9838 14.2343 22.0778 14.2741 22.1614 14.3309C22.2451 14.3877 22.3167 14.4604 22.3722 14.5449C22.4277 14.6294 22.466 14.724 22.485 14.8233C22.5039 14.9226 22.503 15.0247 22.4825 15.1237L21.3788 20.4546C21.3357 20.6628 21.2123 20.8339 21.0477 20.942C20.8832 21.0502 20.6771 21.0955 20.4689 21.0524C20.3699 21.0319 20.2759 20.9922 20.1923 20.9354C20.1086 20.8786 20.037 20.8058 19.9815 20.7213C19.926 20.6368 19.8877 20.5422 19.8687 20.4429C19.8498 20.3436 19.8507 20.2415 19.8712 20.1425L20.5981 16.6316L14.4299 20.6838C14.0745 20.9172 13.5973 20.8187 13.3637 20.4631C13.13 20.1074 13.2291 19.6303 13.5844 19.3969L19.7527 15.3446L16.2417 14.6178C15.8251 14.5315 15.5576 14.1243 15.644 13.7079C15.7303 13.2914 16.1374 13.0238 16.5538 13.1102L21.8848 14.2139Z"
                fill="#f4694b"
              />
            </g>
            <defs>
              <clipPath id="clip0_0_8238">
                <rect
                  width="24.6366"
                  height="24.6366"
                  fill="white"
                  transform="translate(0.80896 13.9722) rotate(-33.303)"
                />
              </clipPath>
            </defs>
          </svg>
        </span>
        <div className="imgWrp d-flex align-items-center justify-content-center rounded-circle circledIcn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
          >
            <g clip-path="url(#clip0_0_8231)">
              <path
                d="M15.2907 2.45773C14.9258 2.09204 14.4405 1.89062 13.9242 1.89062H13.052V1.71484C13.052 1.23021 12.6577 0.835938 12.1731 0.835938H5.84497C5.36034 0.835938 4.96607 1.23021 4.96607 1.71484V1.89062H4.09078C3.02692 1.89062 2.16042 2.75709 2.15923 3.82207L2.14478 16.9002C2.14421 17.4171 2.34485 17.9031 2.70974 18.2688C3.07466 18.6345 3.55999 18.8359 4.07633 18.8359H13.9097C14.9736 18.8359 15.8401 17.9695 15.8412 16.9045L15.8557 3.82636C15.8563 3.30946 15.6556 2.82339 15.2907 2.45773ZM6.02075 1.89062H11.9973V2.94531H6.02075V1.89062ZM14.7877 16.9033C14.7871 17.3874 14.3932 17.7812 13.9097 17.7812H4.07633C3.84163 17.7812 3.62102 17.6897 3.45516 17.5235C3.28929 17.3572 3.19809 17.1363 3.19834 16.9014L3.21279 3.82327C3.21332 3.33913 3.60717 2.94531 4.09078 2.94531H4.96607V3.12109C4.96607 3.60572 5.36034 4 5.84497 4H12.1731C12.6577 4 13.052 3.60572 13.052 3.12109V2.94531H13.9242C14.1589 2.94531 14.3795 3.03686 14.5453 3.20308C14.7112 3.3693 14.8024 3.59025 14.8022 3.82517L14.7877 16.9033Z"
                fill="#548B54"
              />
              <path
                d="M9.17955 7.8672H12.9262C13.2174 7.8672 13.4535 7.63109 13.4535 7.33985C13.4535 7.04862 13.2174 6.81251 12.9262 6.81251H9.17955C8.88832 6.81251 8.65221 7.04862 8.65221 7.33985C8.65221 7.63109 8.88832 7.8672 9.17955 7.8672ZM9.17955 11.3828H12.9262C13.2174 11.3828 13.4535 11.1467 13.4535 10.8555C13.4535 10.5642 13.2174 10.3281 12.9262 10.3281H9.17955C8.88832 10.3281 8.65221 10.5642 8.65221 10.8555C8.65221 11.1467 8.88832 11.3828 9.17955 11.3828ZM12.9413 13.8438H9.17955C8.88832 13.8438 8.65221 14.0799 8.65221 14.3711C8.65221 14.6623 8.88832 14.8984 9.17955 14.8984H12.9413C13.2325 14.8984 13.4686 14.6623 13.4686 14.3711C13.4686 14.0799 13.2325 13.8438 12.9413 13.8438ZM6.93507 5.90397L5.71895 7.12009L5.43221 6.83332C5.22627 6.62738 4.89239 6.62738 4.68644 6.83332C4.4805 7.03923 4.4805 7.37315 4.68644 7.57909L5.34608 8.23876C5.44497 8.33766 5.5791 8.39321 5.71896 8.39321C5.85882 8.39321 5.99295 8.33766 6.09185 8.23876L7.68088 6.64977C7.88682 6.44386 7.88682 6.10995 7.68088 5.904C7.47493 5.69802 7.14105 5.69802 6.93507 5.90397ZM6.93507 9.68808L5.71895 10.9042L5.43221 10.6175C5.22627 10.4115 4.89239 10.4115 4.68644 10.6175C4.4805 10.8234 4.4805 11.1573 4.68644 11.3632L5.34608 12.0229C5.44497 12.1218 5.5791 12.1774 5.71896 12.1774C5.85882 12.1774 5.99295 12.1218 6.09185 12.0229L7.68088 10.4339C7.88682 10.228 7.88682 9.89406 7.68088 9.68811C7.47493 9.48217 7.14102 9.48217 6.93507 9.68808ZM6.93507 13.2037L5.71895 14.4198L5.43221 14.1331C5.22627 13.9272 4.89239 13.9272 4.68644 14.1331C4.4805 14.339 4.4805 14.6729 4.68644 14.8789L5.34608 15.5385C5.44497 15.6374 5.5791 15.693 5.71896 15.693C5.85882 15.693 5.99295 15.6374 6.09185 15.5385L7.68088 13.9495C7.88682 13.7436 7.88682 13.4097 7.68088 13.2037C7.47493 12.9978 7.14102 12.9978 6.93507 13.2037Z"
                fill="#548B54"
              />
            </g>
            <defs>
              <clipPath id="clip0_0_8231">
                <rect
                  width="18"
                  height="18"
                  fill="white"
                  transform="translate(0.000244141 0.835938)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div className="content pe-3">
          <h3 className="m-0 fw-sbold">
            {todo.form_name}
          </h3>
          <p className="m-0">{moment(todo.date).format('MMM D, YYYY h:ss A')}</p>
          <p className="text-dark fw-sbold m-0 pt-2 mt-1 ">
          {formatToDoStatus(todo.status)}
          </p>
        </div>
      </Link>
    </Col>
  )
}

export default ToDoItem;