import React, { useContext } from "react";
import {
  Accordion,
  Button
} from "react-bootstrap";
import AccordionContext from 'react-bootstrap/AccordionContext';
import AllQuestionAnswers from "./Component/AllQuestionAnswers/Index";
import NutritionalAssessmentV1 from "./Component/NutritionalAssessmentV1/Index";
import NutritionalAssessmentV2 from "./Component/NutritionalAssessmentV2/Index";
import { connect } from "react-redux";
import FoodMoodJournal from "../AccordionContent/Food&MoodJournal/Index";

const SubmittedData = ({ completedTodoItems, userProfile }) => {
  const { activeEventKey } = useContext(AccordionContext);

  return (
    <Accordion className="my-3 ColoredAcorrdion">
      {completedTodoItems && completedTodoItems.length > 0 ?(
        completedTodoItems.map(todo => {
          switch(todo.form_id){
            case 4:
              return (
                <NutritionalAssessmentV2
                  key={todo.id}
                  todoId={todo.id}
                  formName={todo.form_name}
                  formId={todo.form_id}
                  submittedOn={todo.updated_at}
                  userProfile={userProfile}
                />
              )

            case 12:
              return (
                <NutritionalAssessmentV1
                  key={todo.id}
                  todoId={todo.id}
                  formName={todo.form_name}
                  formId={todo.form_id}
                  submittedOn={todo.updated_at}
                  userProfile={userProfile}
                />
              )

            case 21:
              return (
                <FoodMoodJournal
                  key={todo.id}
                  todoId={todo.id}
                  formName={todo.form_name}
                  formId={todo.form_id}
                  submittedOn={todo.updated_at}
                  userProfile={userProfile}
                />
              )
            default:
              return (
                <AllQuestionAnswers
                  key={todo.id}
                  todoId={todo.id}
                  formName={todo.form_name}
                  submittedOn={todo.updated_at}
                  userProfile={userProfile}
                />
              )
          }
        })
      ):(
        <p>No data found</p>
      )}
    </Accordion>
  )
}

const mapStateToPros = (state) => {
  return {
    userProfile: state.Auth?.user,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SubmittedData);