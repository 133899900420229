import { useEffect, useRef } from "react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import MyRoutes from "./Routes/routes";
import store from "./store";
import { authCheck } from "./store/actions/auth";

import "react-toastify/dist/ReactToastify.css";
import 'Assets/css/timepicker.css';
import "./App.css";

const ZENDESK_API_KEY = process.env.REACT_APP_ZENDESK_API_KEY;

store.dispatch(authCheck());

function App() {
  const zendeskRef = useRef(null);
  const zendeskScriptLoaded = useRef(null);
  
  // useEffect(() => {
  //   // return if zendesk chat widget already injected
  //   if(zendeskScriptLoaded.current) {
  //     return;
  //   }
  //   const scriptTag = document.createElement("script");
  //   scriptTag.id = "ze-snippet";
  //   scriptTag.src = `https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_API_KEY}`;

  //   // append script to zendesk container
  //   zendeskRef.current.appendChild(scriptTag);
  //   zendeskScriptLoaded.current = true;
  // }, []);

  return (
    <>
      <Provider store={store}>
        <ToastContainer />
        {/* <Suspense fallback={<div>Loading...</div>}> */}
        <MyRoutes />
        {/* </Suspense> */}
        <div id="nta-zendesk-widget" ref={zendeskRef}></div>
      </Provider>
    </>
  );
}

export default App;
