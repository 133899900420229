import { Formik } from "formik";
import { cloneDeep } from "lodash";
import moment from "moment";
import TimePicker from "rc-time-picker";
import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { logFoodOrFeeling, updateFoodOrFeelingLog } from "store/services/foodMoodJournalService";
import * as Yup from 'yup';
import LogUpdatedPopup from "./Component/Modals/LogUpdatedPopup";
import { TIME_FORMAT_24 } from "common/constants";
import { parseTimeString } from "helpers/common";
import * as Path from "Routes/paths";

const singleFoodRow = {
  eaten: "",
  size: ""
}

const LogMeal = ({ editMealData, user }) => {
  const [showLogUpdatedPopup, setShowLogUpdatedPopup] = useState(false);

  const navigate = useNavigate();

  const logTimeMoment = editMealData?.log_time ? moment(editMealData?.log_time, ['h:m a', 'H:m']) : moment();

  const initialValues = {
    log_type: "meal",
    meal_type: editMealData?.meal_type || "",
    log_date: editMealData?.log_date ?
      moment(editMealData?.log_date).format("YYYY-MM-DD")
      :
      moment().format("YYYY-MM-DD"),
    log_time: moment(logTimeMoment).format(TIME_FORMAT_24),
    food_served: editMealData?.food_served || [],
  }

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      setSubmitting(true);
      if (editMealData) {
        // update meal log
        await updateFoodOrFeelingLog(editMealData.id, values);
      } else {
        // add new meal log
        await logFoodOrFeeling(values);
      }
      setSubmitting(false);
      setShowLogUpdatedPopup(true);
      resetForm();
    } catch (err) {
      console.log("Error saving meal log", err);
      setSubmitting(false);
    }
  }

  const addFood = (e, values, setValues) => {
    const foodServed = cloneDeep(values.food_served);
    const newFoodRow = {
      ...singleFoodRow,
      eaten: e?.value,
    }
    foodServed.push(newFoodRow);
    setValues("food_served", foodServed);
  }

  const removeFood = (index, values, setValues) => {
    const foodServed = cloneDeep(values.food_served);
    foodServed.splice(index, 1);
    setValues("food_served", foodServed);
  }

  const Option = (props) => {
    return (
      <Link
      >
        <components.Option {...props} className="d-flex justify-content-between align-items-center">
          {props.children}
          <Link
            className="p-2"
          >
            Add to Meal
          </Link>
        </components.Option>
      </Link>
    );
  };

  const getLogTime = (logTime) => {
    const parsedTime = parseTimeString(logTime);
    const parsedHours = parsedTime.get("hour");
    const parsedMinutes = parsedTime.get("minute");
    return moment().set("hour", parsedHours).set("minute", parsedMinutes);
  }

  if(user?.client_anytime_logging === "no") {
    navigate(Path.dashboard);
  }

  return (
    <section className="toDo position-relative">
      <LogUpdatedPopup
        showPopup={showLogUpdatedPopup}
        closePopup={() => {
          setShowLogUpdatedPopup(false);
        }}
      />
      <Container fluid>
        <Row>
          <Col lg="12" className="my-2">
            <h3 className="m-0 py-1">Log a Meal or Snack</h3>
            <h6 className="m-0 py-1">What'd you just eat ?</h6>
          </Col>
        </Row>
        <Row>
          <Col lg="6" className="my-2">
            <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={validation}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
              }) => {
                // console.log("Errors", errors, values)
                return (
                  <Form onSubmit={handleSubmit} autoComplete="off" >
                    <Row>
                      <Col lg="12" className="my-2">
                        <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                          Date of Meal
                        </label>
                        <input
                          type="date"
                          className={`form-control ${errors.log_date && 'is-invalid'}`}
                          name="log_date"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.log_date}
                          autoComplete="off"
                        // disabled
                        />
                        {(errors.log_date && touched.log_date) &&
                          <p className="invalid-feedback">{errors.log_date}</p>
                        }
                      </Col>
                      <Col lg="12" className="my-2">
                        <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                          Time of Meal
                        </label>
                        <div className="">
                          <TimePicker
                            showSecond={false}
                            defaultValue={getLogTime(values.log_time)}
                            className={`form-control ${errors.log_time && 'is-invalid'}`}
                            onChange={(value) => {
                              setFieldValue("log_time", value ? moment(value).format(TIME_FORMAT_24) : "");
                            }}
                            format={TIME_FORMAT_24}
                            use12Hours={false}
                          />
                          {(errors.log_time && touched.log_time) &&
                            <p className="invalid-feedback">{errors.log_time}</p>
                          }
                        </div>
                      </Col>
                      <Col lg="12" className="my-2">
                        <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                          Meal Type
                        </label>
                        <Form.Select
                          className={`form-control ${errors.meal_type && 'is-invalid'}`}
                          name="meal_type"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.meal_type}
                          autoComplete="off"
                        >
                          <option value="">Select Meal Type</option>
                          <option value="breakfast">Breakfast</option>
                          <option value="lunch">Lunch</option>
                          <option value="dinner">Dinner</option>
                          <option value="snack">Snack</option>
                        </Form.Select>
                        {(errors.meal_type && touched.meal_type) &&
                          <p className="invalid-feedback">{errors.meal_type}</p>
                        }
                      </Col>
                      <Col lg="12" className="my-2 mb-4">
                        <label htmlFor="" className="form-label m-0 py-1 fw-sbold">
                          Foods Eaten
                        </label>
                        <CreatableSelect
                          // options={options}
                          components={{ Option }}
                          onChange={(e) => {
                            addFood(e, values, setFieldValue);
                          }}
                        />
                      </Col>
                      <Row>
                        <hr></hr>
                        <Col lg="8">
                          <div className={`foods-container ${errors.food_served && 'is-invalid'}`}>
                            {values.food_served.map((row, index) => {
                              let fieldError = errors?.food_served && errors?.food_served[index]?.size;
                              return (
                                <Col lg="12" className="py-2">
                                  <h6>{row.eaten}</h6>
                                  <div className={`d-flex gap-10 ${fieldError && 'is-invalid'}`}>
                                    <Form.Select
                                      className={`form-control ${fieldError && 'is-invalid'}`}
                                      name={`food_served[${index}].size`}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.food_served[index]?.size}
                                      autoComplete="off"
                                    >
                                      <option value="">Select Serving Size</option>
                                      <option value="Tiny">Tiny</option>
                                      <option value="Small">Small</option>
                                      <option value="Regular">Regular</option>
                                      <option value="Large">Large</option>
                                      <option value="Huge">Huge</option>
                                    </Form.Select>
                                    <Button
                                      variant="danger"
                                      onClick={() => {
                                        removeFood(index, values, setFieldValue);
                                      }}
                                      size="sm"
                                    >
                                      Remove
                                    </Button>
                                  </div>
                                  {(fieldError) &&
                                    <p className="invalid-feedback">{fieldError}</p>
                                  }
                                </Col>
                              )
                            })}
                          </div>
                          {errors.food_served && typeof errors.food_served == "string" &&
                            <p className="invalid-feedback">{errors.food_served}</p>
                          }
                        </Col>
                      </Row>
                      <Col lg="12" className="my-2">
                        <Button
                          type="submit"
                          className="d-flex btn text-white align-items-center fw-bold justify-content-center commonBtn w-100"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? 'Saving...' : 'Submit Meal'}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )
              }}
            </Formik>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const validation = Yup.object({
  log_date: Yup.date().required("Meal date is required"),
  meal_type: Yup.string().required("Meal type is required"),
  log_time: Yup.string().required("Meal time is required"),
  food_served: Yup.array()
    .of(
      Yup.object().shape({
        eaten: Yup.string(),
        size: Yup.string().required("Serving size is required"),
      })
    )
    .required("Required")
    .min(1, "Please add at least 1 food and then submit your meal"),
});

const mapStateToPros = (state) => {
  return {
    user: state.Profile.user,
    practitioner: state.Auth.practitionerDetails,
    editMealData: state.MyLog.editMealData,
  }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(LogMeal);
