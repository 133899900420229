const Address = ({ question }) => {
  let answer = question.answer && JSON.parse(question.answer);
  const { address_1 = '', address_2 = '', city = '', state = '', country = '', zip_code = '', phone = '', phone_type = '' } = answer;
  return (
    <>
      <p className="m-0">
        {address_1}{address_2 ? ', ' + address_2 : ''}
      </p>
      <p className="m-0">
        {city}{state ? ', ' + state : ''}{zip_code ? ', ' + zip_code : ''}{country ? ', ' + country : ''}
      </p>
      <p className="m-0">
        {phone && (
          <span>
            <strong>Phone: </strong>
            {phone}
          </span>
        )}
        <br></br>
        {phone_type && (
          <span>
            <strong>Phone Type: </strong>
            {phone_type}
          </span>
        )}
      </p>
    </>
  )
}

export default Address;