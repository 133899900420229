import { CLEAR_TODO_DATA, GET_TODO, SAVE_TODO_RESPONSE, TODO_AUTOSAVE_IN_PROGRESS, VIEW_TODO } from 'store/actions/todo';

const initialState = {
    items: [],
    loading: true,
    currentTodoData: {
      formDetails: {},
      questions: [],
      allQuestionAnswers: {},
    },
    totalItemsCount: 0,
    itemsCountPerPage: 0,
    autoSaveInProgress: false,
};

//get 
const getToDo = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }
    const stateObj = {
        ...state,
        items: data.body || [],
        itemsCountPerPage: data.body.per_page,
        totalItemsCount: data.body.total_count,
        loading: false,
    };
  return stateObj;
};


  //update 
const viewToDo = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
        callback(payload);
    }

    let allQuestionAnswers = [];
    if(data.body.questions && data.body.questions.length > 0) {
      for(let i = 0; i < data.body.questions.length; i++){
        let questions = data.body.questions[i]?.questions?.map(q => {
          // if(!q.response.client_response) {
          //   return null;
          // }
          return ({
            id: q.id,
            ans: q.response.client_response && JSON.parse(q.response.client_response).response,
            visible: true,
          })
        });
        // questions = questions.filter(q => !!q);
        allQuestionAnswers = [...allQuestionAnswers, ...questions];
      }
    }

    const stateObj = {
      ...state,
      currentTodoData: {
        ...data.body,
        allQuestionAnswers
      },
    };
    return stateObj;
};

const saveToDoResponse = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
      callback(data);
  }
  
  return state;
};

const clearToDoData = (state, payload) => {
  const stateObj = {
    ...state,
    currentTodoData: {
      ...initialState.currentTodoData
    }
  }

  return stateObj;
}

const setToDoAutoSaveState = (state, payload) => {
  const stateObj = {
    ...state,
    autoSaveInProgress: payload
  }

  return stateObj;
}

const ToDo = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case GET_TODO:
          return getToDo(state, payload);
        case VIEW_TODO:
          return viewToDo(state, payload);
        case SAVE_TODO_RESPONSE:
          return saveToDoResponse(state, payload);
        case CLEAR_TODO_DATA:
          return clearToDoData(state, payload);
        case TODO_AUTOSAVE_IN_PROGRESS:
          return setToDoAutoSaveState(state, payload);
        default:
        return state;
    };
}
export default ToDo;