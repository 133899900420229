import React, { useEffect, useState } from "react";
import { Accordion, Button } from "react-bootstrap";
import moment from "moment";
import parse from 'html-react-parser';

import { ReactComponent as DownloadIcn } from "Assets/Images/icn/download.svg";
import DownloadRecommendationPDF from "components/shared/PDFReportComponents/Recommendations/RecommendationReportPDF";
import { connect } from "react-redux";

const SingleRecommendation = ({
  recommendation,
  getDetails,
  user
}) => {
  const [recommendationDetails, setRecommendationDetails] = useState(null);
  const [isLoading, setLoading] = useState(false)
  const [isOpen, setOpen] = useState(false);
  const [rows, setRows] = useState({});
  const [additionalRecommendation, setAdditionalRecommendation] = useState([]);

  useEffect(() => {
    if (recommendationDetails) {
      let rows = recommendationDetails?.recommendation || [];
      let additionalRecommendation = recommendationDetails?.additional_recommendation || {};
      setRows(rows);
      setAdditionalRecommendation(additionalRecommendation);
    }
  }, [recommendationDetails])

  useEffect(() => {
    if (isOpen) {
      fetchRecommendationDetails();
    }
  }, [isOpen])

  const fetchRecommendationDetails = async () => {
    try {
      if (!recommendation.id) {
        throw new Error("Invalid recommendation ID");
      }
      setLoading(true);
      let response = await getDetails(recommendation.id);
      if (response.success === true && response.body) {
        setRecommendationDetails(response.body);
      }
      setLoading(false);
    } catch (err) {
      console.log("Error occurred while fetching recommendation details", err);
      setLoading(false);
    }
  }

  if (!recommendation) {
    return "";
  }

  return (
    <Accordion.Item
      eventKey={recommendation.id}
      className="recommendation my-2"
      style={{
        border: "1px solid #405C40",
      }}
    >
      <Accordion.Header
        className=""
        style={{
          background: "#FFFDFA",
          color: "#405C40",
        }}
        onClick={() => {
          setOpen(true)
        }}
      >
        <span className="toggleBtn position-absolute"></span>
        <div className="d-flex align-items-center gap-10">
          Recommendation
        </div>
        <div className="right d-flex align-items-center gap-10">
          <p className="m-0 text-dark text-lowercase fw-sbold">
            {`submmitted on ${moment(recommendation.created_at).format("MMM DD, YYYY")}`}
          </p>
        </div>
      </Accordion.Header>
      <Accordion.Body
        style={{
          borderTop: "1px solid #405C40",
        }}
      >
        {isLoading ?
          <p>
            Loading...
          </p>
          :
          recommendationDetails &&
          <div className="AccordionInner">
            <div className="inner">
              <div className="py-2">
                <div className="top d-flex align-items-start justify-content-between gap-10 flex-wrap">
                  <div className="left">
                    {recommendationDetails && recommendationDetails.visit_number &&
                      <h6 className="m-0 fw-bold py-1 mt-2 text-uppercase">
                        Visit# <span className="underline">{recommendationDetails.visit_number}</span>
                      </h6>
                    }
                  </div>
                  <div className="right">
                    <DownloadRecommendationPDF
                      nutritionalRows={rows.nutritional}
                      lifestyleRows={rows.lifestyle}
                      supplementRows={rows.supplement}
                      details={recommendationDetails}
                      additionalDetails={additionalRecommendation}
                      client={user}
                      date={recommendation.created_at}
                    />
                  </div>
                </div>
              </div>

              {/* Nutritional Recommendation */}
              {rows.nutritional && rows.nutritional.length > 0 && (
                <div className="py-2">
                  <div className="py-2">
                    <div className="commonContent">
                      <h3 className="m-0 fw-sbold py-1 text-uppercase">
                        Nutritional Recommendations
                      </h3>
                    </div>
                  </div>
                  <div className="commonCardBox">
                    <div className="table-responsive">
                      <table className="table table-striped recommendation-products">
                        <thead>
                          <tr>
                            <th className="p-3 fw-normal text-dark fw-sbold">
                              Nutritional Recommendation
                            </th>
                            <th className="p-3 fw-normal text-dark fw-sbold">
                              Benefit / Instructions / Notes
                            </th>
                            <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                              Per Day
                            </th>
                            <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                              Upon Waking
                            </th>
                            <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                              With Breakfast
                            </th>
                            <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                              Mid-morning
                            </th>
                            <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                              With Lunch
                            </th>
                            <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                              Mid-afternoon
                            </th>
                            <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                              With Dinner
                            </th>
                            <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                              Before Bed
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <TableRows rows={rows.nutritional} />
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
              {additionalRecommendation && additionalRecommendation.nutritional &&
                <div className="py-2">
                  <div className="commonContent">
                    <h3 className="m-0 fw-sbold py-1 text-uppercase">
                      Additional Nutritional Recommendations
                    </h3>
                    <p className="m-0 py-1 is-multiline">
                      {additionalRecommendation.nutritional}
                    </p>
                  </div>
                </div>
              }

              {/* Lifestyle Recommendation */}
              {rows.lifestyle && rows.lifestyle.length > 0 && (
                <div className="py-2">
                  <div className="py-2">
                    <div className="commonContent">
                      <h3 className="m-0 fw-sbold py-1 text-uppercase">
                        Lifestyle Recommendations
                      </h3>
                    </div>
                  </div>
                  <div className="commonCardBox">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="p-3 fw-normal text-dark fw-sbold">
                              Lifestyle Recommendation
                            </th>
                            <th className="p-3 fw-normal text-dark fw-sbold">
                              Benefit / Instructions / Notes
                            </th>
                            <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                              Per Day
                            </th>
                            <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                              Upon Waking
                            </th>
                            <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                              With Breakfast
                            </th>
                            <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                              Mid-morning
                            </th>
                            <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                              With Lunch
                            </th>
                            <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                              Mid-afternoon
                            </th>
                            <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                              With Dinner
                            </th>
                            <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                              Before Bed
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <TableRows rows={rows.lifestyle} />
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
              {additionalRecommendation && additionalRecommendation.lifestyle &&
                <div className="py-2">
                  <div className="commonContent">
                    <h3 className="m-0 fw-sbold py-1 text-uppercase">
                      Additional Lifestyle Recommendations
                    </h3>
                    <p className="m-0 py-1 is-multiline">
                      {additionalRecommendation.lifestyle}
                    </p>
                  </div>
                </div>
              }

              {/* Supplement Recommendation */}
              {rows.supplement && rows.supplement.length > 0 && (
                <div className="py-2">
                  <div className="py-2">
                    <div className="commonContent">
                      <h3 className="m-0 fw-sbold py-1 text-uppercase">
                        Supplement Recommendations
                      </h3>
                    </div>
                  </div>
                  <div className="commonCardBox">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="p-3 fw-normal text-dark fw-sbold">
                              Supplement Recommendation
                            </th>
                            <th className="p-3 fw-normal text-dark fw-sbold">
                              Benefit / Instructions / Notes
                            </th>
                            <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                              Per Day
                            </th>
                            <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                              Upon Waking
                            </th>
                            <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                              With Breakfast
                            </th>
                            <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                              Mid-morning
                            </th>
                            <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                              With Lunch
                            </th>
                            <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                              Mid-afternoon
                            </th>
                            <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                              With Dinner
                            </th>
                            <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                              Before Bed
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <TableRows rows={rows.supplement} />
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
              {additionalRecommendation && additionalRecommendation.supplement &&
                <div className="py-2">
                  <div className="commonContent">
                    <h3 className="m-0 fw-sbold py-1 text-uppercase">
                      Additional Supplement Recommendations
                    </h3>
                    <p className="m-0 py-1 is-multiline">
                      {additionalRecommendation.supplement}
                    </p>
                  </div>
                </div>
              }

              {/* Client Goals */}
              {recommendationDetails && recommendationDetails.client_goals &&
                <div className="py-2">
                  <div className="commonContent">
                    <h3 className="m-0 fw-sbold py-1 text-uppercase">
                      Client Goals
                    </h3>
                    <p className="m-0 py-1 is-multiline">
                      {recommendationDetails.client_goals}
                    </p>
                  </div>
                </div>
              }
            </div>
          </div>
        }
      </Accordion.Body>
    </Accordion.Item>
  )
}

const TableRows = ({ rows }) => {
  return (
    <>
      {rows && rows.length > 0 &&
        rows.map((row, index) => {
          return (
            <tr key={index}>
              <td className="p-3 border-0">
                <p className="m-0 is-multiline">{row.recommendation && parse(row.recommendation)}</p>
              </td>
              <td className="p-3 border-0 ws-normal">
                <p className="m-0 is-multiline">
                  {row.benefit && parse(row.benefit)}
                </p>
              </td>
              <td className="p-3 border-0">{row.per_day && parse(row.per_day)}</td>
              <td className="p-3 border-0">{row.upon_waking && parse(row.upon_waking)}</td>
              <td className="p-3 border-0">{row.with_breakfast && parse(row.with_breakfast)}</td>
              <td className="p-3 border-0">{row.midmorning && parse(row.midmorning)}</td>
              <td className="p-3 border-0">{row.with_lunch && parse(row.with_lunch)}</td>
              <td className="p-3 border-0">{row.midafternoon && parse(row.midafternoon)}</td>
              <td className="p-3 border-0">{row.with_dinner && parse(row.with_dinner)}</td>
              <td className="p-3 border-0">{row.before_bed && parse(row.before_bed)}</td>
            </tr>
          )
        })
      }
    </>
  )
}

const mapStateToPros = (state) => {
  return {
    user: state.Auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SingleRecommendation);